import { put } from 'redux-saga/effects';

import { tableTakenAction, connectToWebSocketFailureAction } from 'data/reducers';

export function* onSocketErrorSaga(message: string) {
  if (message === 'Table is already taken') {
    yield put(tableTakenAction());
  }
  yield put(connectToWebSocketFailureAction({ status: 404, message }));
}
