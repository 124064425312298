import React from 'react';
import { IconNames } from 'components/atoms';
import { InfoPlaceholder } from 'constants/common';
import {
  Wrapper,
  TableDescription,
  Item,
  ItemInner,
  ItemLabel,
  ItemValue,
  ControlsWrapper,
  Buttons,
  UserButton,
  PowerButton,
  ShuffleButton,
} from './styles';

export interface IProps {
  tableId: string;
  gameId?: string;
  dealerName: string;
  isChangeDealerDisabled: boolean;
  onChangeDealerClick: () => void;
  onCloseTableClick: () => void;
  onShuffleClick: () => void;
  className?: string;
  isActiveShuffle: boolean;
  isAvailableShuffle: boolean;
}

export const Head = ({
  tableId,
  gameId = InfoPlaceholder,
  dealerName,
  isChangeDealerDisabled,
  onChangeDealerClick,
  onCloseTableClick,
  onShuffleClick,
  className,
  isActiveShuffle,
  isAvailableShuffle,
}: IProps) => (
  <Wrapper className={className}>
    <TableDescription>
      <Item>
        <ItemInner>
          <ItemLabel>Table ID</ItemLabel>
          <ItemValue>{tableId}</ItemValue>
        </ItemInner>
      </Item>
      <Item>
        <ItemInner>
          <ItemLabel>Round ID</ItemLabel>
          <ItemValue>{gameId}</ItemValue>
        </ItemInner>
      </Item>
      <Item>
        <ItemInner>
          <ItemLabel>Dealer</ItemLabel>
          <ItemValue>{dealerName}</ItemValue>
        </ItemInner>
        <ItemInner>
          <UserButton
            disabled={isChangeDealerDisabled}
            onClick={onChangeDealerClick}
            iconName={IconNames.People}
          />
        </ItemInner>
      </Item>
    </TableDescription>
    <ControlsWrapper>
      <Buttons>
        {isAvailableShuffle && (
          <ShuffleButton
            disabled={!isActiveShuffle}
            onClick={onShuffleClick}
            iconName={IconNames.Shuffle}
          />
        )}
        <PowerButton onClick={onCloseTableClick} iconName={IconNames.Power} />
      </Buttons>
    </ControlsWrapper>
  </Wrapper>
);
