import { Icon } from 'components/atoms';
import fontService, { FontWeight } from 'services/FontService';
import styled, { css } from 'styled-components';
import { ResultBall } from 'types';

export const Wrapper = styled.div<{ value?: string; hasFixedTop: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  justify-content: ${({ hasFixedTop }) => (hasFixedTop ? 'flex-start' : 'center')};
  align-items: center;
  height: 592px;
  width: 100%;
  overflow: hidden;

  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 100px;
    z-index: 3;
  }

  &::before {
    top: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 0.7637648809523809) 34%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  /* #12101100, #991414 */
  ${({ value }) => {
    if (value === ResultBall.BLUE) {
      return css`
        background: linear-gradient(135deg, rgba(18, 16, 17, 0) 0%, rgba(0, 111, 156, 0.8) 92.13%),
          linear-gradient(180deg, rgba(18, 16, 17, 0.6) 0%, #121011 86.04%);
      `;
    }

    if (value === ResultBall.RED) {
      return css`
        background: linear-gradient(135deg, rgba(18, 16, 17, 0) 0%, rgba(153, 20, 20, 0.8) 92.13%),
          linear-gradient(180deg, rgba(18, 16, 17, 0.6) 0%, #121011 86.04%);
      `;
    }

    if (value === ResultBall.GOLD) {
      return css`
        background: linear-gradient(135deg, rgba(18, 16, 17, 0) 0%, rgba(174, 150, 0, 0.8) 92.13%),
          linear-gradient(180deg, rgba(18, 16, 17, 0.6) 0%, #121011 86.04%);
      `;
    }

    return css`
      background: linear-gradient(180deg, rgba(18, 16, 17, 0.6) 0%, #121011 86.04%);
    `;
  }};
`;

export const ResultValueBG = styled(Icon)`
  position: absolute;
  top: 0;
  left: 50%;
  height: 600px !important;
  width: auto !important;
  transform: translateX(-50%);
`;

export const ResultValue = styled(Icon)`
  position: relative;
`;

export const ResultLabel = styled.p`
  ${fontService.getFont({ size: 32, lineHeight: 116, weight: FontWeight.bold })};
  width: max-content;
  ${({ theme: { text } }) => css`
    color: ${text.primaryDefault};
  `};
`;

export const IconCard = styled.div<{
  $height: number;
  $numberOfCards: number;
  $modeJoker?: boolean;
}>`
  margin-bottom: 20px;
  svg {
    height: 180px;
    width: auto;
    margin-left: ${({ $numberOfCards, $height, $modeJoker }) =>
      $modeJoker
        ? '0px'
        : `-${$numberOfCards < 2 ? ($numberOfCards === 1 ? 46 : 0) : $height * 0.2}px`};
    height: ${({ $height }) => `${$height}px`};
  }
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 2;
  flex-wrap: wrap;
  justify-content: center;

  margin-left: 40px;
  margin-top: 40px;
  width: 480px;
`;

export const CardsBox = styled.div`
  max-height: 400px;
  width: 540px;
  /* overflow: hidden; */
  overflow-y: scroll;

  overflow-scrolling: touch;
  overflow-x: hidden;
  z-index: 1;

  ::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: 20px;

    background-color: #d4bb93;
  }
  ::-webkit-scrollbar {
    width: 4px;
    background: #434343;
  }
  ::-webkit-scrollbar-track {
    border-radius: 4px;
  }
`;

export const WinnerContent = styled.div`
  border: 2px solid rgba(167, 59, 224, 0.24);
  background: #7107aa;
  padding: 50px 44px;
  ${fontService.getFont({ size: 70, lineHeight: 120, weight: FontWeight.bold })};
  width: max-content;
  border-radius: 8px;
  margin: 27% auto auto;
  letter-spacing: 0.5rem;
  max-width: 80%;
  text-align: center;
  min-width: 300px;
`;

export const WinnerLayout = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
  background: linear-gradient(180deg, rgba(18, 16, 17, 0.6) 40%, #121011 100%);
  display: flex;
`;

export const CardInfoStatus = styled.div<{ isAvailableShuffle: boolean }>`
  ${({ isAvailableShuffle }) => {
    if (isAvailableShuffle) {
      return css`
        position: absolute;
        bottom: 90px;
        left: 50%;
        transform: translateX(-50%);

        & > div {
          transform: scale(1.7);
        }
      `;
    }
    if (!isAvailableShuffle) {
      return css`
        margin-top: 60px;

        & > div {
          transform: scale(1.7);
        }
      `;
    }
  }}
`;

// export const CardInfoStatusJoker = styled.div`
// position: absolute;
// bottom: 90px;
// left: 50%;
// transform: translateX(-50%);

// & > div {
//   transform: scale(1.7);
// }
// `;

export const JokerCard = styled.div<{ $height: number; $numberOfCards: number }>`
  margin-bottom: 20px;
  // margin-top: 5px;
  // margin-left: -153px;
  svg {
    height: 180px;
    width: auto;
    margin-left: ${({ $numberOfCards, $height }) => `-${$numberOfCards < 2 ? 0 : $height * 0.2}px`};
    height: ${({ $height }) => `${$height}px`};
  }
`;

export const Slots = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 50%;
  align-items: flex-start;
  justify-content: center;
  transform: translateX(-50%);
  gap: 24px;
  margin-top: 5px;
`;

export const JokerName = styled.p`
  text-align: center;
  ${fontService.getFont({ size: 24, lineHeight: 24, weight: FontWeight.bold })};
  line-height: normal;
  text-transform: uppercase;
  background: linear-gradient(180deg, #ffc700 39%, #e66e00 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
  top: -40px;
  left: calc(50% - 4px);
  transform: translateX(-50%);
`;

export const SlotCard = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const CardsStatic = styled.div`
  display: flex;

  flex-direction: row;
  position: relative;
  z-index: 2;
  flex-wrap: nowrap;
  margin-left: 0;
  margin-top: 40px;
  gap: 9px;
  width: 328px;
  height: 250px;
  margin-top: 17px;
`;

export const NextCard = styled.div<{ $height: number; $numberOfCards: number }>`
  margin-bottom: 20px;
  border-radius: 12px;
  border: 2px solid rgba(187, 147, 112, 0.24);

  height: 226px;
  width: 152.5px;
`;

export const Wrap = styled.div`
  margin-top: -40px;
`;
