export const colors = {
  Black: '#121011',
  Dark: '#1B191A',
  MediumDark: '#2B2A2B',
  LightDark: '#343434',
  Gray: '#434343',
  LightGray: '#686868',
  ClashGold: '#BB9370',
  Gold: '#D4BB93',
  LightGold: '#E7D5B9',
  EggShell: '#F1E6D2',
  WhiteOff: '#FFF7EB',
  Marsala: '#9E1334',
  Red: '#D00132',
  Ruby: '#ED1A4C',
  Blue: '#127BAD',
  Sky: '#57B5E3',
  SwampGreen: '#20560D',
  Green: '#3C8024',
  LightGreen: '#43AC1D',
  AccentYellow: '#FFC700',
  Orange: '#E66E00',
  LightOrange: '#FFA24D',
};

export const darkThemeColors = {
  text: {
    primaryDefault: colors.WhiteOff,
    primaryDisabled: `${colors.WhiteOff}3d`,
    secondaryDefault: colors.LightGold,
    secondaryDisabled: `${colors.LightGold}99`,
    secondaryDisabledLight: `${colors.LightGold}3d`,
    errorDefault: colors.Ruby,
    errorDisabled: colors.Marsala,
    accentDefault: colors.AccentYellow,
  },
  background: {
    primaryDefault: colors.MediumDark,
    primaryLight: colors.LightDark,
    primaryContrast: colors.Black,
    secondaryDefault: `${colors.ClashGold}29`,
    accentDefault: `${colors.Red}99`,
  },
  button: {
    primary: colors.ClashGold,
    primaryBorder: `${colors.ClashGold}3d`,
    primaryHover: colors.Gold,
    primaryHoverBorder: `${colors.Gold}3d`,
    primaryBorderLight: `${colors.LightGold}3d`,
    primaryBorderLightHover: colors.LightGold,
    primaryBrightBorder: `${colors.Gold}`,

    accept: colors.Green,
    acceptBorder: `${colors.LightGreen}3d`,
    acceptBrightBorder: `${colors.LightGreen}`,
    acceptHover: colors.LightGreen,
    acceptHoverBorder: `${colors.LightGreen}3d`,

    cancel: colors.Gray,
    cancelBorder: `${colors.Gray}3d`,
    cancelHover: colors.LightGray,
    cancelHoverBorder: `${colors.LightGray}3d`,

    info: colors.Blue,
    infoBorder: `${colors.Sky}3d`,
    infoHover: colors.Sky,
    infoBrightBorder: `${colors.Sky}`,
    infoHoverBorder: `${colors.Sky}3d`,

    orange: colors.Orange,
    orangeBorder: `${colors.LightOrange}3d`,
    orangeHover: colors.LightOrange,
    orangeBrightBorder: `${colors.LightOrange}`,
    orangeHoverBorder: `${colors.LightOrange}3d`,

    error: colors.Red,
    errorBorder: `${colors.Ruby}3d`,
    errorHover: colors.Ruby,
    errorHoverBorder: `${colors.Ruby}3d`,

    disabled: colors.LightDark,
    disabledBorder: `${colors.LightDark}3d`,
  },

  // TODO: remove unused

  bodyBackground: 'linear-gradient(180deg, #121011 0%, #2B2A2B 100%)',
  cardBackground: '#FFFFFF',

  primaryTextColor: colors.WhiteOff,
  secondaryTextColor: colors.LightGold,
  disableTextColor: `${colors.WhiteOff}24`,
  tertiaryTextColor: 'rgba(231, 213, 185, 0.6)',
  verifiedTextColor: 'rgba(76, 175, 80, 1)',
  canceledTextColor: 'rgba(244, 67, 54, 1)',
  closedTextColor: 'rgba(0, 0, 0, 0.38)',
  activeFilterTextColor: '#FFFFFF',
  boardPrimaryTextColor: '#8A85FF',
  captionTextColor: 'rgba(231, 213, 185, 0.6)',
  errorTextColor: 'rgba(237, 26, 76, 1)',

  borderColor: 'rgba(231, 213, 185, 0.24)',
  focusedBorderColor: 'rgba(231, 213, 185, 1)',
  linkColor: '#8A85FF',
  activeMenuLink: '#F5F5F5',
  headerBackground: '#222841',

  btnPrimaryColor: 'rgba(187, 147, 112, 1)',
  btnSecondaryColor: '#ffffff',
  btnPrimaryHoverColor: 'rgba(212, 187, 147, 1)',
  btnPrimaryTextColor: '#FFF7EB',
  btnSecondaryTextColor: 'rgba(0, 0, 0, 0.87)',

  primaryLoadingIndicatorColor: '#222841',
  secondaryLoadingIndicatorColor: '#ffffff',

  checkboxPrimaryColor: '#8A85FF',

  textFieldActiveBorderColor: '#8A85FF',
  statusPendingBackground: 'rgba(255, 152, 0, .1)',
  statusVerifiedBackground: 'rgba(76, 175, 80, .1)',
  statusCanceledBackground: 'rgba(244, 67, 54, .1)',
  statusClosedBackground: '#F5F5F5',

  filterBackground: '#F5F4F4',
  filterHoverBackground: 'rgba(0, 0, 0, 0.1);',
  filterActiveHoverBackground: '#3d4258',
  filterActiveBackground: '#222841',
  rowDisableBacgroung: 'rgba(245, 245, 245, 0.54)',

  activeTabBorderColor: '#8A85FF',

  dropZoneTextColor: 'rgba(0, 0, 0, 0.87)',

  themeBGColor: 'rgba(43, 42, 43, 1)',
  headerBGColor: '#333333',
  autorizationLogoBGColor: '#222841',
  authorizationBGColor: 'rgba(43, 42, 43, 1)',
  authorizationHeaderBGColor: '#333333',

  tagPrimaryBGColor: 'rgba(187, 147, 112, 0.64)',
  tagPrimaryBorderColor: 'rgba(212, 187, 147, 0.64)',
  tagSecondaryBGColor: 'rgba(60, 128, 36, 0.64)',
  tagSecondaryBorderColor: 'rgba(67, 172, 29, 0.64)',
  tagTertiaryBGColor: 'rgba(18, 123, 173, 0.64)',
  tagTertiaryBorderColor: 'rgba(87, 181, 227, 0.64)',

  cardBGColor: 'linear-gradient(180deg, rgba(52, 52, 52, 0) 0%, #343434 100%)',
  cardBGHoverColor: 'linear-gradient(180deg, rgba(52, 52, 52, 0) 0%, #434343 100%)',
  cardBorderColor: 'rgba(187, 147, 112, 0.16)',

  errorBGColor: 'rgba(158, 19, 52, 0.64)',
  errorBorder: `#ED1A4C3d`,
};
