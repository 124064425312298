import React from 'react';
// import { ResultBall } from 'types';
import * as R from 'ramda';
import { Icon, IconNames } from 'components/atoms';
import { ICard } from 'types';

import {
  IconCard,
  ResultValueBG,
  JokerCard,
  Slots,
  JokerName,
  SlotCard,
  CardsStatic,
  NextCard,
  Wrap,
} from './styles';

export interface IProps {
  cards: ICard[];
  hasUndefinedCard: boolean;
  joker: ICard | null;
}

export const JokerCardsList = ({ cards, hasUndefinedCard, joker }: IProps) => {
  const lengthCards = 1;
  const nextCard = R.last(cards);
  const height = 240;

  return (
    <Wrap>
      {cards !== undefined || hasUndefinedCard ? (
        <ResultValueBG name={IconNames.Background} />
      ) : null}

      <CardsStatic>
        {joker && (
          <IconCard
            key={`${joker?.suit}_${joker?.rank}`}
            $numberOfCards={1}
            $height={height}
            style={{ marginLeft: '-11px' }}
            $modeJoker>
            <Icon name={`${joker?.suit}_${joker?.rank}` as IconNames} />
          </IconCard>
        )}
        {nextCard && !hasUndefinedCard && (
          <IconCard
            key={`${nextCard?.suit}_${nextCard?.rank}`}
            $numberOfCards={1}
            $height={height}
            $modeJoker>
            <Icon name={`${nextCard?.suit}_${nextCard?.rank}` as IconNames} />
          </IconCard>
        )}
        {hasUndefinedCard && (
          <IconCard
            key="undefined_card"
            $numberOfCards={1}
            $height={height}
            style={{ marginLeft: !joker ? '-11px' : '' }}
            $modeJoker>
            <Icon name={IconNames.UndefinedCard} />
          </IconCard>
        )}
        <Slots>
          <SlotCard>
            <JokerName>Joker</JokerName>
            <JokerCard key="JokerCardState" $numberOfCards={lengthCards} $height={226}>
              <Icon name={`JokerCardState` as IconNames} />
            </JokerCard>
          </SlotCard>
          <SlotCard>
            <NextCard $numberOfCards={lengthCards} $height={226} />
          </SlotCard>
        </Slots>
      </CardsStatic>
    </Wrap>
  );
};
