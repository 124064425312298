import { useCallback, useEffect, useState } from 'react';

type TimerHookType = {
  bettingTimer: number;
  drawTimer: number;
  startBettingTimer: () => void;
  reloadBettingTimer: () => void;
  startDrawTimer: () => void;
  reloadDrawTimer: () => void;
};

interface ITimerProps {
  bettingTime: number;
  timerLeft?: {
    bettingTimeLeft?: number;
    drawTimeLeft?: number;
  };
}

export const useTimer = ({ bettingTime, timerLeft }: ITimerProps): TimerHookType => {
  const [bettingTimer, setBettingTimer] = useState<number>(bettingTime);
  const [drawTimer, setDrawTimer] = useState<number>(0);
  const [startedBettingTimer, setStartedBettingTimer] = useState<boolean>(false);
  const [startedDrawTimer, setStartedDrawTimer] = useState<boolean>(false);

  const startBettingTimer = useCallback(() => {
    setStartedBettingTimer(true);
  }, [setStartedBettingTimer]);

  const startDrawTimer = useCallback(() => {
    setStartedDrawTimer(true);
  }, [setStartedDrawTimer]);

  const reloadBettingTimer = useCallback(() => {
    setStartedBettingTimer(false);
    setBettingTimer(
      timerLeft?.bettingTimeLeft === 0
        ? timerLeft?.bettingTimeLeft
        : timerLeft?.bettingTimeLeft || bettingTime,
    );
  }, [setBettingTimer, bettingTime, timerLeft]);

  const reloadDrawTimer = useCallback(() => {
    setStartedDrawTimer(false);
    setDrawTimer(0);
  }, [setDrawTimer]);

  useEffect(() => {
    if (startedBettingTimer) {
      const timer = setInterval(() => {
        if (bettingTimer > 0) {
          setBettingTimer(bettingTimer - 1);
        }
        if (bettingTimer === 0) {
          setStartedBettingTimer(false);
          clearInterval(timer);
        }
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [bettingTimer, startedBettingTimer]);

  useEffect(() => {
    if (startedDrawTimer) {
      const timer = setInterval(() => {
        setDrawTimer(drawTimer + 1);
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [startedDrawTimer, drawTimer]);

  useEffect(() => {
    if (timerLeft?.drawTimeLeft) {
      setDrawTimer(timerLeft?.drawTimeLeft);
    }
  }, [timerLeft?.drawTimeLeft]);

  useEffect(() => {
    if (timerLeft?.bettingTimeLeft) {
      setBettingTimer(timerLeft?.bettingTimeLeft);
    }
  }, [timerLeft?.bettingTimeLeft]);
  return {
    bettingTimer,
    drawTimer,
    startBettingTimer,
    reloadBettingTimer,
    startDrawTimer,
    reloadDrawTimer,
  };
};
