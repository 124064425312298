import {
  roundCanceledAction,
  changeGameStateAction,
  changeGameStatusAction,
  addToastAction,
  clearCardsToStore,
  setIsBettingTime,
  setIsCancelRound,
  setIsRoundEnd,
  setIsScanTime,
  setIsStartRound,
  setCardsStatusToStore,
  setHasDoubleCardToStore,
  setHasUndefinedCardToStore,
  setWinnerToStore,
} from 'data/reducers';
import { put } from 'redux-saga/effects';
import { Notifications, RoundCanceledResponse, TableStates, ToastTypes } from 'types';
import { gameStatus, alerts } from 'constants/common';

export function* roundCanceled({ type: notificationType, data: result }: RoundCanceledResponse) {
  if (notificationType === Notifications.ROUND_CANCELED) {
    yield put(setCardsStatusToStore(null));
    yield put(setHasUndefinedCardToStore(false));
    yield put(setHasDoubleCardToStore(false));
    yield put(changeGameStateAction({ tableState: TableStates.RoundCanceled }));
    yield put(changeGameStatusAction({ status: gameStatus.OpenRound }));
    yield put(roundCanceledAction());
    yield put(setWinnerToStore(null));
    if (result.fromAdmin) {
      yield put(addToastAction({ type: ToastTypes.WARNING, value: alerts.ROUND_CANCELED }));
    }

    yield put(clearCardsToStore());
    yield put(setIsStartRound(false));
    yield put(setIsBettingTime(false));
    yield put(setIsCancelRound(false));
    yield put(setIsScanTime(false));
    yield put(setIsRoundEnd(false));
  }
}
