import { put } from 'redux-saga/effects';
import {
  connectToWebSocketSuccessAction,
  changeDealerSuccessAction,
  saveGameIdToStore,
  saveGameDataToStore,
  changeGameStateAction,
  changeGameStatusAction,
  // saveRoundDataToStore,
  saveRoundsResultsToStore,
  changeTimeLeftAction,
  openTableSuccessAction,
  setIsBettingTime,
  setIsCancelRound,
  setIsStartRound,
  setIsScanTime,
  setIsRoundEnd,
  setCardsToStore,
  setWinnerToStore,
  setCardsStatusToStore,
  additionalBurnCardBettingTimeAction,
  additionalShuffleBettingTimeAction,
  setJokerCardToStore,
  setIsJokerCardRound,
  // setIsJokerCardRound,
} from 'data/reducers';
import { getFinishBettingTimeLeft } from 'helpers';
import { DealerJoinedResponse, Notifications, TableStates } from 'types';
import { gameCardsStatus, gameStatus } from 'constants/common';
import { isEmpty } from 'ramda';

export function* dealerJoined({ type: responseType, data }: DealerJoinedResponse) {
  if (responseType === Notifications.DEALER_CONNECTED) {
    yield put(connectToWebSocketSuccessAction());

    const joker = data.state && data.state.joker;

    if (data.state && !isEmpty(data.state) && data.state.table) {
      const state = data.state.status;
      yield put(openTableSuccessAction());
      yield put(changeDealerSuccessAction({ dealer: data.state?.dealer }));
      yield put(saveGameIdToStore({ gameId: data.state?.round?.gameId || '-' }));
      yield put(saveGameDataToStore({ selectedTableData: data.state.table }));
      yield put(changeGameStateAction({ tableState: state }));
      yield put(saveRoundsResultsToStore({ drawResults: data.state.last_balls }));

      switch (state) {
        case TableStates.TableOpened:
          if (data.state?.table?.joker_high_low && !joker) {
            yield put(changeGameStatusAction({ status: gameStatus.JokerCard }));
            yield put(setCardsStatusToStore(''));
            yield put(setIsStartRound(true));
            yield put(setIsJokerCardRound(false));
            return;
          }
          if (data.state?.table?.joker_high_low && joker) {
            yield put(setJokerCardToStore(joker));
            yield put(changeGameStatusAction({ status: '' }));
            yield put(setCardsStatusToStore(''));
            yield put(changeGameStatusAction({ status: gameStatus.OpenRound }));
            yield put(setIsStartRound(false));
            yield put(setIsJokerCardRound(true));
            if (data.state?.round?.gameCards?.length) {
              yield put(setCardsToStore(data.state?.round?.gameCards));
            }
            return;
          }
          yield put(changeGameStatusAction({ status: gameStatus.OpenRound }));
          break;
        case TableStates.BettingTimeStarted:
          if (data.state.round?.startedAt) {
            yield put(setIsStartRound(true));
            yield put(setIsBettingTime(true));
            yield put(setIsCancelRound(true));
            if (data.state?.table?.joker_high_low && joker) {
              yield put(setJokerCardToStore(joker));
              yield put(setIsJokerCardRound(true));
            }
            if (data.state?.round?.gameCards?.length) {
              yield put(setCardsToStore(data.state?.round?.gameCards));
            }
            yield put(
              additionalBurnCardBettingTimeAction({
                burnCardRequired: data.state?.round?.burnCardRequired as boolean,
              }),
            );
            yield put(
              additionalShuffleBettingTimeAction({
                shuffleRequired: data.state?.round?.shuffleRequired as boolean,
              }),
            );
            yield put(changeGameStatusAction({ status: gameStatus.BettingTime }));
            if (data.state.round.finishBettingTime) {
              yield put(
                changeTimeLeftAction({
                  bettingTimeLeft: getFinishBettingTimeLeft(data.state.round.finishBettingTime),
                }),
              );
            }
          }

          break;
        case TableStates.BettingTimeFinished:
          yield put(changeGameStatusAction({ status: gameStatus.NoMoreBets }));
          break;
        case TableStates.DrawStarted:
          yield put(setIsStartRound(true));
          yield put(setIsCancelRound(true));
          if (data.state?.round?.gameCards?.length) {
            yield put(setCardsToStore(data.state?.round?.gameCards));
          }
          if (data.state?.table?.joker_high_low && joker) {
            yield put(setJokerCardToStore(joker));
            yield put(setIsJokerCardRound(true));
          }
          yield put(changeGameStatusAction({ status: gameStatus.NoMoreBets }));
          yield put(changeGameStateAction({ tableState: TableStates.BettingTimeFinished }));
          yield put(setIsScanTime(true));
          yield put(
            setCardsStatusToStore(
              data.state?.round?.gameCards?.length
                ? gameCardsStatus.WaitingNextCard
                : gameCardsStatus.ScanFirstCard,
            ),
          );
          break;
        case TableStates.DrawFinished:
          yield put(setIsStartRound(true));
          yield put(setIsCancelRound(true));
          if (data.state?.table?.joker_high_low && joker) {
            yield put(setJokerCardToStore(joker));
            yield put(setIsJokerCardRound(true));
          }
          if (data.state?.round?.gameCards?.length) {
            yield put(setCardsToStore(data.state?.round?.gameCards));
          }
          if (data.state?.round?.winner) {
            yield put(setWinnerToStore(data.state?.round?.winner));
          }
          yield put(changeGameStatusAction({ status: gameStatus.EndRound }));
          yield put(changeGameStateAction({ tableState: TableStates.BettingTimeFinished }));
          yield put(setIsScanTime(false));
          yield put(setIsRoundEnd(true));
          break;
        case TableStates.RoundFinished:
          if (data.state?.table?.joker_high_low && !joker) {
            yield put(changeGameStatusAction({ status: gameStatus.JokerCard }));
            yield put(setIsJokerCardRound(false));
            yield put(setCardsStatusToStore(''));
            yield put(setIsStartRound(true));
            return;
          }

          if (data.state?.table?.joker_high_low && joker) {
            yield put(setJokerCardToStore(joker));
            yield put(changeGameStatusAction({ status: '' }));
            yield put(setCardsStatusToStore(''));
            yield put(changeGameStatusAction({ status: gameStatus.OpenRound }));
            yield put(setIsStartRound(false));
            yield put(setIsJokerCardRound(true));
            if (data.state?.round?.gameCards?.length) {
              yield put(setCardsToStore(data.state?.round?.gameCards));
            }
            return;
          }
          yield put(changeGameStatusAction({ status: gameStatus.OpenRound }));
          break;
        case TableStates.RoundCanceled:
          if (data.state?.table?.joker_high_low && !joker) {
            yield put(changeGameStatusAction({ status: gameStatus.JokerCard }));
            yield put(setCardsStatusToStore(''));
            yield put(setIsStartRound(true));
            yield put(setIsJokerCardRound(false));
            return;
          }

          if (data.state?.table?.joker_high_low && joker) {
            yield put(setJokerCardToStore(joker));
            yield put(changeGameStatusAction({ status: '' }));
            yield put(setCardsStatusToStore(''));
            yield put(changeGameStatusAction({ status: gameStatus.OpenRound }));
            yield put(setIsStartRound(false));
            yield put(setIsJokerCardRound(true));
            if (data.state?.round?.gameCards?.length) {
              yield put(setCardsToStore(data.state?.round?.gameCards));
            }
            return;
          }
          yield put(changeGameStatusAction({ status: gameStatus.OpenRound }));
          break;
        default:
      }
    }
  }
}
