import { call, put } from 'redux-saga/effects';

import { Notifications } from 'types';
import { webSocketService } from 'services/WebSocketService';
import { ActionType } from 'typesafe-actions';
import {
  addNewJokerCardEvent,
  setCardsStatusToStore,
  setHasDoubleCardToStore,
  setHasUndefinedCardToStore,
} from 'data/reducers';

export function* addNewJokerCardSaga({
  payload: { barcode },
}: ActionType<typeof addNewJokerCardEvent>) {
  yield put(setCardsStatusToStore(null));
  yield put(setHasUndefinedCardToStore(false));
  yield put(setHasDoubleCardToStore(false));

  const { type, data } = yield call(() =>
    webSocketService.sendMessage({
      type: Notifications.CHANGE_JOKER,
      data: { barcode: barcode.trim() },
    }),
  );

  if (type === 'ERROR' && data?.reason === 'NOT_REGISTERED') {
    yield put(setHasUndefinedCardToStore(true));
  }

  if (type === 'ERROR' && data?.reason === 'ALREADY_PLAYED') {
    yield put(setHasDoubleCardToStore(true));
  }
}
