import React from 'react';
import { TableStates } from 'types';
import {
  Header,
  StatusCaption,
  StatusSeparator,
  StatusValue,
  Time,
  TimeUnits,
  TimeValue,
  Wrapper,
  StatusCardsValue,
} from './styles';

export interface IProps {
  status: TableStates;
  timeValue?: number;
  isFinished: boolean;
  statusText: string;
  className?: string;
  cardsStatus: string | null;
}

export const StatusInfo = ({
  timeValue,
  status,
  isFinished,
  statusText,
  className,
  cardsStatus,
}: IProps) => (
  <Wrapper className={className}>
    <Header>
      <StatusCaption>Status</StatusCaption>
      <StatusValue>{statusText}</StatusValue>
    </Header>
    <StatusSeparator />
    {!cardsStatus && (
      <Time finished={isFinished} status={status}>
        <TimeValue>{timeValue}</TimeValue>
        <TimeUnits>sec</TimeUnits>
      </Time>
    )}
    {cardsStatus && <StatusCardsValue>{cardsStatus}</StatusCardsValue>}
  </Wrapper>
);
