import { put } from 'redux-saga/effects';
import {
  bettingTimeStartedAction,
  changeGameStateAction,
  changeGameStatusAction,
  changeTimeLeftAction,
  setCardsStatusToStore,
  setIsBettingTime,
  setIsCancelRound,
  setIsScanTime,
  setIsStartRound,
} from 'data/reducers';
import { BettingTimeStartedResponse, Notifications, TableStates } from 'types';
import { gameStatus } from 'constants/common';

export function* bettingTimeStarted({
  type: notificationType,
  data: result,
}: BettingTimeStartedResponse) {
  if (notificationType === Notifications.BETTING_TIME_STARTED) {
    const { bettingTime, additional, burnCardRequired, shuffleRequired } = result;
    yield put(changeGameStatusAction({ status: gameStatus.BettingTime }));
    yield put(changeGameStateAction({ tableState: TableStates.BettingTimeStarted }));
    yield put(
      bettingTimeStartedAction({ bettingTime, additional, burnCardRequired, shuffleRequired }),
    );
    yield put(
      changeTimeLeftAction({
        bettingTimeLeft: undefined,
      }),
    );
    yield put(setIsStartRound(true));
    yield put(setIsBettingTime(true));
    yield put(setIsCancelRound(true));
    yield put(setIsScanTime(false));
    yield put(setCardsStatusToStore(null));
  }
}
