export const getBettingTimeLeft = (time: string, bettingTime: number): number => {
  const date = new Date(time);
  const dateNow = new Date();
  const difference = Math.floor((dateNow.getTime() - date.getTime()) / 1000);

  const bettingTimeLeft = bettingTime - difference < 0 ? 0 : bettingTime - difference;
  return bettingTimeLeft;
};

export const getFinishBettingTimeLeft = (time: string): number => {
  const date = new Date(time);
  const dateNow = new Date();
  const difference = Math.floor((date.getTime() - dateNow.getTime()) / 1000);
  const bettingTimeLeft = difference < 0 ? 0 : difference;
  return bettingTimeLeft;
};
