import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getTables } from 'core/selectors';
import {
  clearCardsToStore,
  clearGameDataAction,
  clearSagaChannels,
  clearSelectedTableToStore,
  closeAction,
  disconnectFromWebSocketAction,
  getTablesRequest,
  setCardsStatusToStore,
  setHasDoubleCardToStore,
  setHasUndefinedCardToStore,
  setIsBettingTime,
  setIsBurnCardWindowToStore,
  setIsCancelRound,
  setIsRoundEnd,
  setIsScanTime,
  setIsShuffleWindowToStore,
  setIsStartRound,
  setWinnerToStore,
} from 'data/reducers';
import { useRequestLoading } from 'hooks/useRequestLoading';

import { Tables } from './Tables';

export const Container = () => {
  const dispatch = useDispatch();
  const tablesData = useSelector(getTables);
  const loadingTables = useRequestLoading(getTablesRequest);

  useEffect(() => {
    dispatch(clearGameDataAction());
    dispatch(clearSelectedTableToStore());
    dispatch(clearCardsToStore());
    dispatch(setIsStartRound(false));
    dispatch(setIsBettingTime(false));
    dispatch(setIsCancelRound(false));
    dispatch(setIsScanTime(false));
    dispatch(setIsRoundEnd(false));
    dispatch(setHasUndefinedCardToStore(false));
    dispatch(setHasDoubleCardToStore(false));
    dispatch(disconnectFromWebSocketAction());
    dispatch(clearSagaChannels());
    dispatch(closeAction());
    dispatch(setWinnerToStore(null));
    dispatch(setCardsStatusToStore(null));
    dispatch(setIsBurnCardWindowToStore(false));
    dispatch(setIsShuffleWindowToStore(false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTablesRequest());
  }, [dispatch]);

  return <Tables tablesData={tablesData} loading={loadingTables} />;
};
