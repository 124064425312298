import { call } from 'redux-saga/effects';

import { Notifications } from 'types';
import { webSocketService } from 'services/WebSocketService';

// @TODO help

export function* startRoundSaga() {
  // yield put(changeGameStateAction({ tableState: TableStates.BettingTimeStarted }));
  // yield put(changeGameStatusAction({ status: gameStatus.BettingTime }));

  // yield delay(10000);
  // yield put(changeGameStatusAction({ status: gameStatus.NoMoreBets }));
  // yield put(setIsBettingTime(false));
  // yield put(setIsScanTime(true));
  yield call(() => webSocketService.sendMessage({ type: Notifications.START_ROUND }));
}
