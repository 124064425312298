import * as React from 'react';
import { memo, useState } from 'react';

import styled, { css } from 'styled-components';
import fontService, { FontWeight } from 'services/FontService';
import { Icon, IconNames } from '../Icon';

export interface IInput extends IInputStyle {
  value?: string;
  placeholder?: string;
  label?: string;
  message?: string;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  isPasswordType?: boolean;
  isPasswordAbleToBeShown?: boolean;
  max?: number;
}

export interface IInputStyle {
  color?: string;
  disabled?: boolean;
  hasError?: boolean;
  hasWarning?: boolean;
  className?: string;
  hideInputValue?: boolean;
}

const InputComponent = React.forwardRef<HTMLInputElement, IInput>(
  (
    {
      value,
      name,
      label,
      message,
      onChange,
      onFocus,
      onBlur,
      isPasswordType = false,
      isPasswordAbleToBeShown = false,
      max,
      hasError,
      hideInputValue,
      className,
      ...rest
    }: IInput,
    ref,
  ) => {
    const [passwordIsVisible, togglePasswordIsVisible] = useState(false);

    return (
      <Wrapper className={className}>
        <InputLabel show={Boolean(label)}>{label}</InputLabel>
        <InputContainer>
          <InputStyled
            value={value}
            name={name}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            maxLength={max}
            hideInputValue={hideInputValue}
            type={isPasswordType && !passwordIsVisible ? 'password' : 'text'}
            hasError={Boolean(hasError)}
            ref={ref}
            {...rest}
          />
          {isPasswordType && isPasswordAbleToBeShown ? (
            <IconTouchableWrapper onClick={() => togglePasswordIsVisible((prev) => !prev)}>
              <Icon name={passwordIsVisible ? IconNames.OpenEye : IconNames.CloseEye} />
            </IconTouchableWrapper>
          ) : null}
        </InputContainer>

        <InputMessage show={Boolean(message)} hasError={Boolean(hasError)}>
          {message}
        </InputMessage>
      </Wrapper>
    );
  },
);

export const Input = memo(InputComponent);

const Wrapper = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
`;

const InputLabel = styled.p<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  color: ${({ theme: { primaryTextColor } }) => primaryTextColor};
  background-color: transparent;
  ${fontService.text({ size: 14, lineHeight: 143, weight: FontWeight.medium })};
  margin: 0 0 8px 0;
  letter-spacing: 0.02em;
  transform: scale(1, 1.1);
`;

const InputMessage = styled.p<{ show: boolean; hasError: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  height: 100%;
  width: 100%;
  margin-top: 4px;
  ${fontService.text({ size: 12, lineHeight: 167, weight: FontWeight.regular })};
  color: ${({ hasError, theme: { primaryTextColor, errorTextColor } }) =>
    hasError ? errorTextColor : primaryTextColor};
`;

const InputStyled = styled.input<IInputStyle>`
  width: 100%;
  padding: 8px 12px;
  box-sizing: border-box;
  background-color: transparent !important;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ hasError, theme: { borderColor, errorTextColor } }) =>
    hasError ? errorTextColor : borderColor};
  border-radius: 2px;
  color: ${({ hasError, theme: { primaryTextColor, errorTextColor } }) =>
    hasError ? errorTextColor : primaryTextColor};
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 171%;
  font-weight: 400;
  cursor: pointer;
  letter-spacing: 0.1em;
  outline: none;
  ${({ hideInputValue }) => {
    if (hideInputValue) {
      return css`
        -webkit-text-security: disc;
      `;
    }
  }}
  ${({ disabled, theme: { borderColor, primaryTextColor } }) => {
    if (disabled) {
      return css`
        color: ${primaryTextColor};
        border-color: ${borderColor};
        user-select: none;
        cursor: default;
      `;
    }
  }}
  &:focus {
    border-color: ${({ hasError, theme: { focusedBorderColor, errorTextColor } }) =>
      hasError ? errorTextColor : focusedBorderColor};
  }

  &::placeholder {
    color: rgba(255, 247, 235, 0.4);
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
  }

  &::-ms-input-placeholder {
    color: rgba(255, 247, 235, 0.4);
    font-style: normal;
    font-weight: 400;
  }
`;

const IconTouchableWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 13px;
  width: 40px;
  cursor: pointer;
`;

const InputContainer = styled.div`
  position: relative;
`;
