import { Button, ButtonControl } from 'components/atoms';
import styled, { css } from 'styled-components';
import { ControlsButtonColors } from './Controls';

// TODO: storybook can't recognize enum value as object's key
const BUTTON_STYLES = {
  GREEN: css`
    ${({ theme: { button } }) => css`
      border-color: ${button.acceptBorder};
      background-color: ${button.accept};

      &:hover {
        background-color: ${button.acceptHover};
      }
    `}
  `,
  RED: css`
    ${({ theme: { button } }) => css`
      border-color: ${button.errorBorder};
      background-color: ${button.error};

      &:hover {
        background-color: ${button.errorHover};
      }
    `}
  `,
  BROWN: css`
    ${({ theme: { button } }) => css`
      border-color: ${button.primaryBorder};
      background-color: ${button.primary};

      &:hover {
        background-color: ${button.primaryHover};
      }
    `}
  `,
  BLUE: css`
    ${({ theme: { button } }) => css`
      border-color: ${button.infoBorder};
      background-color: ${button.info};

      &:hover {
        background-color: ${button.infoHover};
      }
    `}
  `,
  ORANGE: css`
    ${({ theme: { button } }) => css`
      border-color: ${button.orangeBorder};
      background-color: ${button.orange};

      &:hover {
        background-color: ${button.orangeHover};
      }
    `}
  `,
};

const disabledButtonStyles = css`
  ${({ theme: { text, button } }) => css`
    border-color: ${button.cancelDisabledBorder};
    background-color: ${button.cancelDisabled};
    color: ${text.primaryDisabled};
  `}
`;

export const Wrapper = styled.div``;

export const ControlsTopWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
`;

export const ControlsBottomWrapper = styled.div<{ isSmallSize: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin-top: 24px;
  min-height: ${({ isSmallSize }) => (isSmallSize ? '80px' : '140px')};
`;

export const ButtonStyled = styled(Button)<{ disabled: boolean; color: ControlsButtonColors }>`
  text-transform: uppercase;
  ${({ theme }) => css`
    color: ${theme.text.primaryDefault};
  `}

  ${({ disabled, color }) => {
    if (disabled) {
      return disabledButtonStyles;
    }

    return BUTTON_STYLES[color];
  }}
`;

export const ButtonControlStyled = styled(ButtonControl)<{
  disabled: boolean;
  color: ControlsButtonColors;
  isSmallSize: boolean;
  width?: number;
}>`
  white-space: unset;

  ${({ disabled, color, theme }) => {
    if (disabled) {
      return css`
        ${disabledButtonStyles};

        & > svg > path {
          fill: ${theme.text.primaryDisabled};
        }
      `;
    }

    return BUTTON_STYLES[color];
  }}
`;
