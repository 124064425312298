import fontService, { FontWeight, TextType } from 'services/FontService';
import styled, { css } from 'styled-components';
import { TableStates } from 'types';

export const Wrapper = styled.div`
  padding: 24px;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(52, 52, 52, 0) 0%, #343434 100%);

  ${({ theme: { background } }) => css`
    border-color: ${background.secondaryDefault};
  `}
`;

export const Header = styled.div``;

export const StatusCaption = styled.p`
  ${fontService.text({ size: 14, lineHeight: 100, weight: FontWeight.medium })};
  text-transform: uppercase;

  ${({ theme: { text } }) => css`
    color: ${text.secondaryDisabled};
  `}
`;

export const StatusValue = styled.p`
  margin-top: 12px;
  min-height: 24px;
  ${fontService.text({ type: TextType.large, weight: FontWeight.bold })};

  ${({ theme: { text } }) => css`
    color: ${text.primaryDefault};
  `}
`;

export const StatusSeparator = styled.div`
  height: 1px;
  width: 100%;
  margin: 16px 0;

  ${({ theme: { text } }) => `
    background-color: ${text.primaryDisabled};
  `}
`;

export const Time = styled.div<{ finished: boolean; status: TableStates }>`
  display: flex;
  min-height: 60px;

  ${({ status }) => `
  visibility: ${
    status === TableStates.BettingTimeStarted ||
    status === TableStates.DrawStarted ||
    status === TableStates.DrawFinished
      ? 'visible'
      : 'hidden'
  };
  `}

  ${({ finished, theme: { text } }) => {
    if (finished) {
      return css`
        color: ${text.accentDefault};
      `;
    }

    return css`
      color: ${text.primaryDefault};
    `;
  }}
`;

export const TimeValue = styled.p`
  ${fontService.text({ size: 48, weight: FontWeight.bold })};
  line-height: 55.58px;
`;

export const TimeUnits = styled.p`
  margin: auto 0 8px 8px;
  ${fontService.text({ size: 16, weight: FontWeight.bold })};
`;

export const StatusCardsValue = styled.p`
  margin-top: 12px;
  ${fontService.text({ size: 40, weight: FontWeight.bold })};
  min-height: 60px;

  ${({ theme: { text } }) => css`
    color: ${text.primaryDefault};
  `}
`;
