import React from 'react';
import { SelectOptionType, ResultBall } from 'types';
import { Popup } from '../Popup/Popup';
import { SelectResult } from '../SelectResult';
import { ConfirmButton, SelectWrapper, Subtitle } from './styles';

export interface IProps {
  options: SelectOptionType<ResultBall>[];
  value: ResultBall | undefined;
  loading: boolean;
  onChange: (value: ResultBall) => void;
  onConfirmClick: () => void;
  onClose: () => void;
}

export const PopupChangeResultComponent = ({
  options,
  value,
  loading,
  onChange,
  onConfirmClick,
  onClose,
}: IProps) => (
  <Popup title="Change result" onClose={onClose} loading={loading}>
    <Subtitle>Color ball</Subtitle>
    <SelectWrapper>
      <SelectResult options={options} value={value} onChange={onChange} />
    </SelectWrapper>
    <ConfirmButton disabled={value === undefined} loading={loading} onClick={onConfirmClick}>
      Confirm
    </ConfirmButton>
  </Popup>
);
