import React from 'react';
import * as R from 'ramda';
// import { ResultBall } from 'types';
import { ICard, ToastTypes } from 'types';

import { cardsInfo } from 'helpers/cardsHelper';
import { ResultLabel, Wrapper, WinnerLayout, WinnerContent, CardInfoStatus } from './styles';
import { Alert } from '../Alert';
// import { ListCards } from './ListCards';
import { JokerCardsList } from './JokerCardsList';
import { ListCards } from './ListCards';

export interface IProps {
  cards: ICard[];
  winner: string | null;
  hasDoubleCard: boolean;
  hasUndefinedCard: boolean;
  joker: ICard | null;
  isAvailableShuffle: boolean;
}

export const DrawResult = ({
  cards,
  winner,
  hasDoubleCard,
  hasUndefinedCard,
  joker,
  isAvailableShuffle,
}: IProps) => {
  const lastCard = isAvailableShuffle && !cards?.length ? joker : R.last(cards);
  const cardCode = `${lastCard?.suit}_${lastCard?.rank}`;
  const cardName = lastCard ? cardsInfo[cardCode as any] : null;

  return (
    <Wrapper hasFixedTop={cards?.length > 29}>
      {!isAvailableShuffle && (
        <ListCards
          cards={cards}
          hasDoubleCard={hasDoubleCard}
          hasUndefinedCard={hasUndefinedCard}
        />
      )}
      {isAvailableShuffle && (
        <JokerCardsList cards={cards} joker={joker} hasUndefinedCard={hasUndefinedCard} />
      )}
      {winner && (
        <WinnerLayout>
          <WinnerContent>{winner}</WinnerContent>
        </WinnerLayout>
      )}
      <CardInfoStatus isAvailableShuffle={isAvailableShuffle}>
        {hasUndefinedCard && (
          <Alert variant={ToastTypes.ERROR} showIcon>
            Undefined
          </Alert>
        )}
        {hasDoubleCard && (
          <Alert variant={ToastTypes.WARNING} showIcon>
            Double scan
          </Alert>
        )}

        {cardName && !hasUndefinedCard && !hasDoubleCard && <ResultLabel>{cardName}</ResultLabel>}
      </CardInfoStatus>
    </Wrapper>
  );
};
