import { all } from 'redux-saga/effects';

import {
  initSaga,
  authorizationSaga,
  tableSaga,
  startListeningWebSocketMessagesSaga,
} from 'data/sagas';

export function* rootSaga() {
  try {
    yield all([
      initSaga(),
      authorizationSaga(),
      tableSaga(),
      startListeningWebSocketMessagesSaga(),
    ]);
  } catch (e) {
    global.console.log(e);
  }
}
