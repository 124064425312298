import { ActionType, createAction, createReducer } from 'typesafe-actions';

import { IAuthState, ILogInFormValues, ILogInToSocketValues } from 'types';

import { ACTIONS } from '../constants';

export const refreshTokenRequstAction = createAction(ACTIONS.REFRESH_TOKEN_REQUEST)();

export const loginRequstAction = createAction(ACTIONS.LOGIN_REQUEST)<ILogInFormValues>();
export const loginSuccessAction = createAction(ACTIONS.LOGIN_SUCCESS)();
export const loginFailureAction = createAction(ACTIONS.LOGIN_FAILURE)<any>();

export const loginToSocketRequestAction = createAction(
  ACTIONS.LOGIN_TO_SOCKET_REQUEST,
)<ILogInToSocketValues>();
export const loginToSocketSuccessAction = createAction(ACTIONS.LOGIN_TO_SOCKET_SUCCESS)();
export const loginToSocketFailureAction = createAction(ACTIONS.LOGIN_TO_SOCKET_FAILURE)<any>();

export const logOutAction = createAction(ACTIONS.LOGOUT)();
export const putLogInToStoreAction = createAction(ACTIONS.PUT_LOGIN_TO_STORE)<boolean>();

export const defaultState: IAuthState = {
  isLoggedIn: false,
};

const actions = {
  loginSuccessAction,
  putLogInToStoreAction,
};

export const authReducer = createReducer<IAuthState, ActionType<typeof actions>>(defaultState)
  .handleAction(loginSuccessAction, (state) => ({
    ...state,
    isLoggedIn: true,
  }))
  .handleAction(putLogInToStoreAction, (state, { payload: isLoggedIn }) => ({
    ...state,
    isLoggedIn,
  }));
