import styled, { css } from 'styled-components';
import fontService, { FontWeight } from 'services/FontService';
import { Icon } from '../Icon';
import { Button } from '../Button';

export const ButtonStyled = styled(Button)<{ isSmallSize: boolean; width?: number }>`
  min-width: min-content;
  flex-direction: column;
  flex-direction: ${({ isSmallSize }) => (isSmallSize ? 'row' : 'column')};
  padding: ${({ isSmallSize }) => (isSmallSize ? '20px' : '24px')};
  width: ${({ isSmallSize, width }) => (isSmallSize && width ? `${width}px` : 'auto')};
  cursor: pointer;

  ${({ theme }) => css`
    color: ${theme.text.primaryDefault};
  `}
`;

export const ButtonIcon = styled(Icon)<{ isSmallSize: boolean }>`
  height: ${({ isSmallSize }) => (isSmallSize ? '32px' : '48px')};
  width: ${({ isSmallSize }) => (isSmallSize ? '32px' : '48px')};
  margin-right: ${({ isSmallSize }) => (isSmallSize ? '8px' : '0')};
`;

export const ButtonText = styled.p<{ isSmallSize: boolean }>`
  ${fontService.text({ size: 16, lineHeight: 125, weight: FontWeight.extraBold })};
  text-transform: uppercase;

  margin-top: ${({ isSmallSize }) => (isSmallSize ? '0' : '16px')};
`;
