import { put } from 'redux-saga/effects';
import {
  addCardToStore,
  setCardsStatusToStore,
  setIsScanTime,
  // setIsRoundEnd,
  // saveRoundDataToStore,
  // changeGameStatusAction,
  // drawFinishedSuccessAction,
  // changeGameStateAction,
  // setIsScanTime,
} from 'data/reducers';
import { AddedCardResponse, Notifications } from 'types';
import { gameCardsStatus } from 'constants/common';
// import { gameStatus } from 'constants/common';

export function* addedNewCard({ type: notificationType, data: result }: AddedCardResponse) {
  if (notificationType === Notifications.NEW_CARD_ADDED) {
    const { card } = result;
    yield put(addCardToStore(card));
    yield put(setIsScanTime(false));
    yield put(setCardsStatusToStore(gameCardsStatus.Wait));
    // yield put(saveRoundDataToStore({ winner, drawTime }));
    // yield put(changeGameStatusAction({ status: `${gameStatus.ConfirmResult} ${drawTime} sec` }));
    // yield put(changeGameStateAction({ tableState: TableStates.DrawFinished }));
    // yield put(drawFinishedSuccessAction());
  }
}
