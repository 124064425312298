import { IState } from 'core/types';

export const getTables = ({ tables: { tablesData } }: IState) => tablesData;

export const getSelectedTableData = ({ tables: { selectedTableData } }: IState) =>
  selectedTableData;

export const getSelectedTableRoundInfo = ({
  tables: {
    isBettingTime,
    isRoundEnd,
    isScanTime,
    isStartRound,
    isCancelRound,
    cards,
    winner,
    isBurnCardWindow,
    isShuffleWindow,
    cardsStatus,
    hasDoubleCard,
    hasUndefinedCard,
    isJokerCardRound,
    joker,
  },
}: IState) => ({
  isBettingTime,
  isRoundEnd,
  isScanTime,
  isStartRound,
  isCancelRound,
  cards,
  winner,
  isBurnCardWindow,
  isShuffleWindow,
  cardsStatus,
  hasDoubleCard,
  hasUndefinedCard,
  isJokerCardRound,
  joker,
});
