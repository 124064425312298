import { put } from 'redux-saga/effects';
import { refreshTokenRequstAction } from 'data/reducers';
import { Notifications, SocketPongResponse } from 'types';

export function* socketPongSaga({ data: result, type: responseType }: SocketPongResponse) {
  if (responseType === Notifications.PONG) {
    const { token } = result;
    if (token) {
      sessionStorage.setItem('access_to_ws_token', token);
    }
    yield put(refreshTokenRequstAction());
  }
}
