import { tableCloseNotificationAction, addToastAction } from 'data/reducers';
import { put } from 'redux-saga/effects';
import { TableCloseVerifyResponse, Notifications, ToastTypes } from 'types';
import { alerts } from 'constants/common';

export function* tableCloseNotification({ type: notificationType }: TableCloseVerifyResponse) {
  if (notificationType === Notifications.NOTIFICATION) {
    yield put(tableCloseNotificationAction());
    yield put(addToastAction({ type: ToastTypes.WARNING, value: alerts.TABLE_CLOSED }));
  }
}
