import React from 'react';

import { IconNames } from 'components/atoms';
// import { IEnabledButton } from 'types/common';

import {
  Wrapper,
  ControlsTopWrapper,
  ControlsBottomWrapper,
  ButtonControlStyled,
  ButtonStyled,
} from './styles';

export enum ControlsButtonColors {
  GREEN = 'GREEN',
  RED = 'RED',
  BROWN = 'BROWN',
  BLUE = 'BLUE',
  ORANGE = 'ORANGE',
}

export interface IProps {
  openRoundLoading: boolean;
  // startDrawLoading: boolean;
  confirmResultLoading: boolean;
  onOpenRoundClick: () => void;
  // onStartDrawClick: () => void;
  onConfirmResultClick: () => void;
  // onChangeResultClick: () => void;
  onCancelRoundClick: () => void;
  onJokerCardRoundClick: () => void;
  // enabledButtons: IEnabledButton;
  className?: string;
  isStartRound: boolean;
  isRoundEnd: boolean;
  isCancelRound: boolean;
  isJokerCardRound: boolean;
  isJokerCard: boolean;
}

export const Controls = ({
  isStartRound,
  isRoundEnd,
  isCancelRound,
  openRoundLoading,
  isJokerCardRound,
  // startDrawLoading,
  confirmResultLoading,
  // enabledButtons: {
  // isButtonConfirmResultEnabled,
  // isButtonChangeResultEnabled,
  // isButtonCancelRoundEnabled,
  // isButtonOpenRoundEnabled,
  // isButtonStartDrawEnabled,
  // },
  onOpenRoundClick,
  // onStartDrawClick,
  onConfirmResultClick,
  // onChangeResultClick,
  onCancelRoundClick,
  onJokerCardRoundClick,
  className,
  isJokerCard,
}: IProps) => (
  <Wrapper className={className}>
    <ControlsTopWrapper>
      {isJokerCard && (
        <ButtonStyled
          onClick={onJokerCardRoundClick}
          loading={openRoundLoading}
          disabled={isJokerCardRound}
          color={ControlsButtonColors.ORANGE}>
          Joker Card
        </ButtonStyled>
      )}
      <ButtonStyled
        onClick={onOpenRoundClick}
        loading={openRoundLoading}
        disabled={isStartRound}
        color={ControlsButtonColors.GREEN}>
        Start round
      </ButtonStyled>
    </ControlsTopWrapper>
    <ControlsBottomWrapper isSmallSize>
      <ButtonControlStyled
        iconName={IconNames.CheckCircle}
        onClick={onConfirmResultClick}
        loading={confirmResultLoading}
        disabled={!isRoundEnd}
        color={ControlsButtonColors.GREEN}
        isSmallSize={isJokerCard}>
        Round end
      </ButtonControlStyled>
      <ButtonControlStyled
        iconName={IconNames.Replay}
        onClick={onCancelRoundClick}
        disabled={!isCancelRound}
        color={ControlsButtonColors.BLUE}
        isSmallSize={isJokerCard}
        width={234}>
        Cancel round
      </ButtonControlStyled>
    </ControlsBottomWrapper>
  </Wrapper>
);
