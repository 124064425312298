import { call, select } from 'redux-saga/effects';

import { Notifications } from 'types';
import { webSocketService } from 'services/WebSocketService';
import { getGameData } from 'core/selectors';

// @TODO help

export function* finishRoundSaga() {
  const { gameId } = yield select(getGameData);

  // yield put(changeGameStateAction({ tableState: TableStates.BettingTimeStarted }));
  // yield put(changeGameStatusAction({ status: gameStatus.BettingTime }));

  // yield delay(10000);
  // yield put(changeGameStatusAction({ status: gameStatus.NoMoreBets }));
  // yield put(setIsBettingTime(false));
  // yield put(setIsScanTime(true));
  yield call(() =>
    webSocketService.sendMessage({ type: Notifications.FINISH_ROUND, data: { gameId } }),
  );
}
