import { put } from 'redux-saga/effects';
import {
  openTableSuccessAction,
  changeGameStateAction,
  saveGameDataToStore,
  changeGameStatusAction,
  setCardsStatusToStore,
  setIsJokerCardRound,
  setIsStartRound,
  setJokerCardToStore,
} from 'data/reducers';
import { Notifications, TableOpenedResponse, TableStates } from 'types';
import { gameStatus } from 'constants/common';
import { isEmpty } from 'ramda';

export function* tableOpened({ type: responseType, data }: TableOpenedResponse) {
  if (responseType === Notifications.TABLE_OPENED) {
    yield put(changeGameStateAction({ tableState: TableStates.TableOpened }));
    yield put(openTableSuccessAction());

    if (data.state && !isEmpty(data.state)) {
      yield put(saveGameDataToStore({ selectedTableData: data.state }));
    }

    const joker = data?.gameState?.joker;
    if (data.state?.joker_high_low && !joker) {
      yield put(changeGameStatusAction({ status: gameStatus.JokerCard }));
      yield put(setCardsStatusToStore(''));
      yield put(setIsStartRound(true));
      yield put(setIsJokerCardRound(false));
      yield put(setJokerCardToStore(joker));
      return;
    }
    if (data.gameState?.table?.joker_high_low && joker) {
      yield put(setJokerCardToStore(joker));
      yield put(changeGameStatusAction({ status: '' }));
      yield put(setCardsStatusToStore(''));
      yield put(changeGameStatusAction({ status: gameStatus.OpenRound }));
      yield put(setIsStartRound(false));
      return;
    }

    yield put(changeGameStatusAction({ status: gameStatus.OpenRound }));
  }
}
