import { Button, ButtonVariants } from 'components/atoms';
import React, { ChangeEvent, FormEvent, memo, useMemo } from 'react';
import { ICustomError } from 'types';
import { errorMessages } from 'constants/common';
import { Keypad } from '../Keypad';
import { Popup } from '../Popup/Popup';
import { IPopupVerificationValues } from './PopupVerificationContainer';
import { ButtonsWrapper, Form, KeypadWrapper, ErrorMessage, InputStyled } from './styles';

export interface IProps {
  title: string;
  label?: string;
  formValues: IPopupVerificationValues;
  hideInputValue?: boolean;
  maxLengthInputValue?: number;
  error?: ICustomError;
  loading: boolean;
  inputIdRef?: React.RefObject<HTMLInputElement>;
  onCancel: () => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onKeypadClick: (keyValue: string) => void;
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
}

// TODO: use redux-form
export const PopupVerificationComponent = memo(
  ({
    title,
    label,
    formValues,
    hideInputValue = false,
    maxLengthInputValue,
    error,
    loading,
    inputIdRef,
    onCancel,
    handleSubmit,
    onKeypadClick,
    onInputChange,
    onClear,
  }: IProps) => {
    const isValueEmpty: boolean = useMemo(() => !formValues.id.length, [formValues.id.length]);

    return (
      <Popup title={title} onClose={onCancel} loading={loading}>
        <Form onSubmit={handleSubmit}>
          <InputStyled
            value={formValues.id}
            label={label}
            onChange={onInputChange}
            hasError={Boolean(error)}
            hideInputValue={hideInputValue}
            max={maxLengthInputValue}
            ref={inputIdRef}
            placeholder="Enter ID"
          />
          {error?.status === 403 ? <ErrorMessage>{errorMessages.IncorrectId}</ErrorMessage> : null}
          {error?.status === 400 ? (
            <ErrorMessage>{errorMessages.NotAvailableId}</ErrorMessage>
          ) : null}
          <KeypadWrapper>
            <Keypad onClick={onKeypadClick} />
          </KeypadWrapper>
          <ButtonsWrapper>
            <Button type="submit" loading={loading} disabled={isValueEmpty}>
              Confirm
            </Button>
            <Button
              type="reset"
              variant={ButtonVariants.Cancel}
              onClick={onClear}
              disabled={isValueEmpty || loading}>
              Clear
            </Button>
          </ButtonsWrapper>
        </Form>
      </Popup>
    );
  },
);
