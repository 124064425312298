import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { tableMock } from 'mocks/tableMock';
import { ITable, TableStates } from 'types/tables';
import { DealerType, IDataResponse, TableCommonType, ToastType } from 'types';
import { TablesClosingStatus } from 'constants/tables';
// import { queue } from 'helpers';
import { ACTIONS } from '../constants';

export interface IGameState {
  gameData: ITable;
}

export const gameState: IGameState = {
  gameData: { ...tableMock },
};

export const saveGameDataToStore = createAction(ACTIONS.SAVE_GAME_TO_STORE)<{
  selectedTableData: TableCommonType;
}>();

export const changeDealerAction = createAction(ACTIONS.CHANGE_DEALER)<{ dealerId: string }>();
export const changeDealerFailureAction = createAction(
  ACTIONS.CHANGE_DEALER_FAILURE,
)<IDataResponse>();
export const changeDealerSuccessAction = createAction(ACTIONS.CHANGE_DEALER_SUCCESS)<{
  dealer: DealerType;
}>();

export const changeTimeLeftAction = createAction(ACTIONS.CHANGE_TIME_LEFT)<{
  bettingTimeLeft?: number;
  drawTimeLeft?: number;
}>();

export const startRoundAction = createAction(ACTIONS.START_ROUND)();
export const startRoundSuccessAction = createAction(ACTIONS.START_ROUND_SUCCESS)();
export const cancelRoundAction = createAction(ACTIONS.CANCEL_ROUND)<{ shiftManagerId: string }>();
export const cancelRoundFailureAction = createAction(ACTIONS.CANCEL_ROUND_FAILURE)<IDataResponse>();
export const roundCanceledAction = createAction(ACTIONS.CANCEL_ROUND_SUCCESS)();

export const saveGameIdToStore = createAction(ACTIONS.SAVE_GAME_ID_TO_STORE)<{ gameId: string }>();
export const saveRoundsResultsToStore = createAction(ACTIONS.SAVE_ROUNDS_RESULTS_TO_STORE)<{
  drawResults: string[];
}>();
export const changeGameStatusAction = createAction(ACTIONS.CHANGE_GAME_STATUS)<{
  status: string;
}>();
export const changeGameStateAction = createAction(ACTIONS.CHANGE_GAME_STATE)<{
  tableState: TableStates;
}>();
export const bettingTimeStartedAction = createAction(ACTIONS.BETTING_TIME_STARTED)<{
  bettingTime: number;
  additional: boolean;
  burnCardRequired: boolean;
}>();
export const bettingTimeFinishedAction = createAction(ACTIONS.BETTING_TIME_FINISHED)();

export const startDrawAction = createAction(ACTIONS.START_DRAW)();
export const startDrawSuccessAction = createAction(ACTIONS.START_DRAW_SUCCESS)();

export const saveRoundDataToStore = createAction(ACTIONS.SAVE_ROUND_DATA_TO_STORE)<{
  winner: string;
  drawTime: number;
}>();

export const drawFinishedSuccessAction = createAction(ACTIONS.DRAW_FINISHED_SUCCESS)();

export const confirmResultAction = createAction(ACTIONS.CONFIRM_RESULT)();
export const confirmResultSuccessAction = createAction(ACTIONS.CONFIRM_RESULT_SUCCESS)<{
  winner: string;
}>();

export const roundFinishedSuccessAction = createAction(ACTIONS.ROUND_FINISHED_SUCCESS)();

export const tableCloseVerifyAction = createAction(ACTIONS.CLOSE_TABLE_VERIFY)<{
  shiftManagerId: string;
}>();

export const tableCloseVerifyFailureAction = createAction(
  ACTIONS.CLOSE_TABLE_VERIFY_FAILURE,
)<IDataResponse>();
export const tableCloseVerifySuccessAction = createAction(ACTIONS.CLOSE_TABLE_VERIFY_SUCCESS)();

export const shuffleVerifyAction = createAction(ACTIONS.SHUFFLE_VERIFY)<{
  shiftManagerId: string;
}>();
export const shuffleAction = createAction(ACTIONS.SHUFFLE_JOKER)<{
  shiftManagerId: string;
}>();
export const shuffleVerifyFailureAction = createAction(
  ACTIONS.SHUFFLE_JOKER_FAILURE,
)<IDataResponse>();
export const shuffleVerifySuccessAction = createAction(ACTIONS.SHUFFLE_SUCCESS)();

export const tableCloseAction = createAction(ACTIONS.CLOSE_TABLE)<{
  shiftManagerId: string;
  closingStatus: TablesClosingStatus;
}>();

export const tableCloseSuccessAction = createAction(ACTIONS.CLOSE_TABLE_SUCCESS)();

export const tableCloseNotificationAction = createAction(ACTIONS.CLOSE_TABLE_NOTIFICATION)();

export const changeResultVerifyAction = createAction(ACTIONS.CHANGE_RESULT_VERIFY)<{
  shiftManagerId: string;
}>();
export const changeResultVerifyFailureAction = createAction(
  ACTIONS.CHANGE_RESULT_VERIFY_FAILURE,
)<IDataResponse>();
export const changeResultVerifySuccessAction = createAction(ACTIONS.CHANGE_RESULT_VERIFY_SUCCESS)();
export const changeResultAction = createAction(ACTIONS.CHANGE_RESULT)<{
  shiftManagerId: string;
  winner: string;
}>();
export const changeResultSuccessAction = createAction(ACTIONS.CHANGE_RESULT_SUCCESS)();

export const clearGameDataAction = createAction(ACTIONS.CLEAR_GAME_DATA)();

export const addToastAction = createAction(ACTIONS.ADD_TOAST)<ToastType>();
export const removeToastAction = createAction(ACTIONS.REMOVE_TOAST)();

export const additionalBurnCardBettingTimeAction = createAction(ACTIONS.ADDITIONAL_BURN_CARD)<{
  burnCardRequired: boolean;
}>();

export const additionalShuffleBettingTimeAction = createAction(ACTIONS.ADDITIONAL_SHUFFLE)<{
  shuffleRequired: boolean;
}>();

const actions = {
  saveGameDataToStore,
  saveGameIdToStore,
  changeDealerSuccessAction,
  startRoundSuccessAction,
  roundCanceledAction,
  changeGameStatusAction,
  startDrawSuccessAction,
  drawFinishedSuccessAction,
  saveRoundDataToStore,
  confirmResultSuccessAction,
  roundFinishedSuccessAction,
  bettingTimeStartedAction,
  changeGameStateAction,
  tableCloseVerifyAction,
  tableCloseVerifySuccessAction,
  tableCloseAction,
  shuffleVerifyAction,
  shuffleVerifySuccessAction,
  shuffleAction,
  changeResultVerifyAction,
  changeResultVerifySuccessAction,
  changeResultAction,
  changeResultSuccessAction,
  clearGameDataAction,
  saveRoundsResultsToStore,
  tableCloseNotificationAction,
  changeTimeLeftAction,
  addToastAction,
  removeToastAction,
  additionalBurnCardBettingTimeAction,
  additionalShuffleBettingTimeAction,
};

export const gameReducer = createReducer<IGameState, ActionType<typeof actions>>(gameState)
  .handleAction(saveGameDataToStore, (state, { payload: { selectedTableData } }) => ({
    ...state,
    gameData: {
      ...state.gameData,
      table_id: selectedTableData.table_id,
      auto_confirm_result: selectedTableData.auto_confirm_result,
      auto_draw: selectedTableData.auto_draw,
      auto_round: selectedTableData.auto_round,
      betting_time: selectedTableData.betting_time,
      stream_low: selectedTableData.stream_low,
      game_type: selectedTableData.game_type,
      joker_high_low: selectedTableData.joker_high_low,
    },
  }))
  .handleAction(saveRoundsResultsToStore, (state) => ({
    ...state,
    gameData: {
      ...state.gameData,
      round: {
        ...state.gameData.round,
        drawResults: [],
      },
    },
  }))
  .handleAction(saveGameIdToStore, (state, { payload: { gameId } }) => ({
    ...state,
    gameData: { ...state.gameData, gameId },
  }))
  .handleAction(changeDealerSuccessAction, (state, { payload: { dealer } }) => ({
    ...state,
    gameData: { ...state.gameData, dealer },
  }))
  .handleAction(
    bettingTimeStartedAction,
    (state, { payload: { bettingTime, additional, burnCardRequired, shuffleRequired } }) => ({
      ...state,
      gameData: {
        ...state.gameData,
        betting_time: bettingTime,
        additional,
        burnCardRequired,
        shuffleRequired,
      },
    }),
  )
  .handleAction(changeGameStateAction, (state, { payload: { tableState } }) => ({
    ...state,
    gameData: { ...state.gameData, tableState },
  }))

  .handleAction(changeTimeLeftAction, (state, { payload: { bettingTimeLeft, drawTimeLeft } }) => ({
    ...state,
    gameData: {
      ...state.gameData,
      round: {
        ...state.gameData.round,
        timerLeft: {
          bettingTimeLeft,
          drawTimeLeft,
        },
      },
    },
  }))

  .handleAction(roundCanceledAction, (state) => ({
    ...state,
    gameData: {
      ...state.gameData,
      round: {
        // drawResults: [...state.gameData.round?.drawResults],
        drawResults: [],
        winner: undefined,
        isFinished: false,
      },
    },
  }))
  .handleAction(startRoundSuccessAction, (state) => ({
    ...state,
    gameData: { ...state.gameData, tableState: TableStates.RoundStarted },
  }))
  .handleAction(startDrawSuccessAction, (state) => ({
    ...state,
    gameData: { ...state.gameData, tableState: TableStates.DrawStarted },
  }))

  .handleAction(drawFinishedSuccessAction, (state) => ({
    ...state,
    gameData: { ...state.gameData, tableState: TableStates.DrawFinished },
  }))
  .handleAction(changeGameStatusAction, (state, { payload: { status } }) => ({
    ...state,
    gameData: { ...state.gameData, statusText: status },
  }))
  .handleAction(saveRoundDataToStore, (state, { payload: { winner, drawTime } }) => ({
    ...state,
    gameData: {
      ...state.gameData,
      round: { ...state.gameData.round, isFinished: true, winner, timeValue: drawTime },
    },
  }))
  .handleAction(confirmResultSuccessAction, (state) => ({
    ...state,
    gameData: {
      ...state.gameData,
      round: {
        ...state.gameData.round,
        drawResults: [],
        timeValue: undefined,
        winner: undefined,
      },
    },
  }))
  .handleAction(roundFinishedSuccessAction, (state) => ({
    ...state,
    gameData: {
      ...state.gameData,

      round: {
        drawResults: [],
        isFinished: false,
      },
      changeResult: undefined,
    },
  }))
  .handleAction(tableCloseVerifyAction, (state, { payload: { shiftManagerId } }) => ({
    ...state,
    gameData: {
      ...state.gameData,
      closed: {
        shiftManagerId,
      },
    },
  }))
  .handleAction(tableCloseVerifySuccessAction, (state) => ({
    ...state,
    gameData: {
      ...state.gameData,
      closed: {
        ...state.gameData.closed,
        verified: true,
      },
    },
  }))
  .handleAction(tableCloseAction, (state) => ({
    ...state,
    gameData: {
      ...state.gameData,
      closed: {
        ...state.gameData.closed,
        isInProgress: true,
      },
    },
  }))
  .handleAction(shuffleVerifyAction, (state, { payload: { shiftManagerId } }) => ({
    ...state,
    gameData: {
      ...state.gameData,
      shuffle: {
        shiftManagerId,
      },
    },
  }))
  .handleAction(shuffleVerifySuccessAction, (state) => ({
    ...state,
    gameData: {
      ...state.gameData,
      shuffle: {
        ...state.gameData.shuffle,
        verified: true,
      },
    },
  }))
  .handleAction(shuffleAction, (state, { payload: { shiftManagerId } }) => ({
    ...state,
    gameData: {
      ...state.gameData,
      shuffle: {
        ...state.gameData.shuffle,
        isInProgress: true,
        shiftManagerId,
      },
    },
  }))
  .handleAction(tableCloseNotificationAction, (state) => ({
    ...state,
    gameData: {
      ...state.gameData,
      closed: {
        ...state.gameData.closed,
        notification: true,
      },
    },
  }))
  .handleAction(changeResultVerifyAction, (state, { payload: { shiftManagerId } }) => ({
    ...state,
    gameData: {
      ...state.gameData,
      changeResult: {
        shiftManagerId,
      },
    },
  }))
  .handleAction(changeResultVerifySuccessAction, (state) => ({
    ...state,
    gameData: {
      ...state.gameData,
      changeResult: {
        ...state.gameData.changeResult,
        verified: true,
      },
    },
  }))
  .handleAction(changeResultAction, (state) => ({
    ...state,
    gameData: {
      ...state.gameData,
      changeResult: {
        ...state.gameData.changeResult,
        isInProgress: true,
      },
    },
  }))
  .handleAction(changeResultSuccessAction, (state) => ({
    ...state,
    gameData: {
      ...state.gameData,
      changeResult: {
        ...state.gameData.changeResult,
        isChanged: true,
      },
    },
  }))
  .handleAction(addToastAction, (state, { payload }) => ({
    ...state,
    gameData: {
      ...state.gameData,
      toastItem: payload,
    },
  }))
  .handleAction(removeToastAction, (state) => ({
    ...state,
    gameData: {
      ...state.gameData,
      toastItem: null,
    },
  }))
  .handleAction(clearGameDataAction, (state) => ({
    ...state,
    gameData: { ...tableMock },
  }))
  .handleAction(
    additionalBurnCardBettingTimeAction,
    (state, { payload: { burnCardRequired } }) => ({
      ...state,
      gameData: { ...state.gameData, burnCardRequired },
    }),
  )
  .handleAction(additionalShuffleBettingTimeAction, (state, { payload: { shuffleRequired } }) => ({
    ...state,
    gameData: { ...state.gameData, shuffleRequired },
  }));
