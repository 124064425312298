import React, { useCallback, useEffect, memo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { webSocketService } from 'services/WebSocketService';
import {
  PopupVerification,
  PopupVerificationTypes,
  PopupChangeResult,
  DrawResult,
  PopupClosingTable,
  Alert,
} from 'components/molecules';

import { getGameData } from 'core/selectors';
import { useModal } from 'hooks/useModal';
import { useTimer } from 'hooks/useTimer';
import {
  cancelRoundAction,
  changeDealerAction,
  confirmResultAction,
  tableCloseAction,
  tableCloseVerifyAction,
  changeResultVerifyAction,
  changeResultAction,
  connectToWebSocketAction,
  tableClosedAction,
  disconnectFromWebSocketAction,
  clearGameDataAction,
  clearSelectedTableToStore,
  clearSagaChannels,
  addNewCardEvent,
  finishRoundEvent,
  setIsBurnCardWindowToStore,
  setIsShuffleWindowToStore,
  setIsScanTime,
  // changeGameStatusAction,
  addNewJokerCardEvent,
  setCardsStatusToStore,
  shuffleAction,
  setIsJokerCardRound,
  changeGameStatusAction,
  // setIsJokerCardRound,
  // shuffleAction,
  // addCardToStore,
} from 'data/reducers';
import { TableStates, ToastTypes, ICustomError } from 'types';

import { IPopupVerificationValues } from 'components/molecules/PopupVerification/PopupVerificationContainer';
import { TablesClosingStatus } from 'constants/tables';
import { ballOptions, gameCardsStatus } from 'constants/common';

import { tableClosingOptionsMock } from 'mocks/tableClosingOptionsMock';
import { useRequestError, useRequestLoading, useOnRequestSuccess, useButtonControls } from 'hooks';
import { getSelectedTableRoundInfo } from 'core/selectors/tablesSelectors';
import { Button, ButtonSizes, Input } from 'components/atoms';

import {
  Block,
  BurnCardText,
  ButtonWrapper,
  CloseNotificationWrapper,
  ContentWrapper,
  ControlsWrapper,
  GameProvider,
  Line,
  TableControlsStyled,
  TableHeader,
  TableHeadStyled,
  TableStatusInfoStyled,
  ViewWrapper,
  WindowBurnCard,
  WindowBurnCardContent,
  Wrapper,
  BarcodeForm,
  BarcodeFormButton,
  BlockCards,
} from './styles';

export const TablePage = memo(() => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { id: urlTableId } = useParams();

  const {
    isShown: isChangeDealerPopupShown,
    open: openChangeDealerPopup,
    close: closeChangeDealerPopup,
  } = useModal();
  const { isShown: isCancelRoundPopupShown, toggle: toggleCancelRoundPopup } = useModal();
  const { isShown: isCloseTableVerifyPopupShown, toggle: toggleCloseTableVerifyPopup } = useModal();
  const { isShown: isCloseTablePopupShown, toggle: toggleCloseTablePopup } = useModal();
  const { isShown: isShufflePopupShown, toggle: toggleShufflePopup } = useModal();
  const { isShown: isChangeResultVerifyPopupShown, toggle: toggleChangeResultVerifyPopup } =
    useModal();
  const { isShown: isChangeResultPopupShown, toggle: toggleChangeResultPopup } = useModal();
  const {
    table_id: tableId,
    gameId,
    dealer,
    betting_time: bettingTime,
    tableState,
    statusText,
    round: { timeValue, isFinished, timerLeft },
    closed,
    // shuffle,
    changeResult,
    toastItem,
    game_type,
    // additional,
    burnCardRequired,
    shuffleRequired,
    // stream_low,
    joker_high_low,
    shuffle,
  } = useSelector(getGameData);

  const {
    isRoundEnd,
    isScanTime,
    isStartRound,
    isCancelRound,
    isJokerCardRound,
    cards,
    winner,
    isBurnCardWindow,
    isShuffleWindow,
    cardsStatus,
    hasDoubleCard,
    hasUndefinedCard,
    joker,
  } = useSelector(getSelectedTableRoundInfo);

  const {
    bettingTimer,
    drawTimer,
    startBettingTimer,
    startDrawTimer,
    reloadBettingTimer,
    reloadDrawTimer,
  } = useTimer({ bettingTime, timerLeft });

  const [shuffleCrdShowed, setShuffleCrdShowed] = useState(false);
  const [jokerCardTime, setJokerCardTime] = useState(false);

  const { isChangeDealerEnabled, onOpenRoundClick } = useButtonControls();

  const { error: dealerChangedError, clearError: dealerChangedClearError } =
    useRequestError(changeDealerAction);
  const { error: cancelRoundError, clearError: cancelRoundClearError } =
    useRequestError(cancelRoundAction);
  const { clearError: changeResultClearError } = useRequestError(changeResultVerifyAction);
  const { error: closeTableError, clearError: closeTableClearError } =
    useRequestError(tableCloseVerifyAction);
  const { error: shuffleError, clearError: shuffleClearError } = useRequestError(shuffleAction);
  const { error: connectToWebSocketError, clearError: connectToWebSocketClearError } =
    useRequestError(connectToWebSocketAction);

  const changeDealerLoading = useRequestLoading(changeDealerAction);
  const cancelRoundLoading = useRequestLoading(cancelRoundAction);
  const closeTableVerifyLoading = useRequestLoading(tableCloseVerifyAction);
  const confirmResultLoading = useRequestLoading(confirmResultAction);
  const closeTableLoading = useRequestLoading(tableCloseAction);
  const changeResultLoading = useRequestLoading(changeResultAction);
  const shuffleLoading = useRequestLoading(shuffleAction);

  useOnRequestSuccess(tableClosedAction, () => {
    navigate('/tables', { replace: true });
    dispatch(disconnectFromWebSocketAction());
    dispatch(clearSagaChannels());
    dispatch(clearGameDataAction());
    dispatch(clearSelectedTableToStore());
  });

  const onChangeDealer = useCallback(
    ({ id }: { id: string }) => {
      dispatch(changeDealerAction({ dealerId: id }));
    },
    [dispatch],
  );

  const onCancelRoundClick = useCallback(() => {
    toggleCancelRoundPopup();
  }, [toggleCancelRoundPopup]);

  const onCancelRoundSubmit = useCallback(
    (values: IPopupVerificationValues) => {
      dispatch(cancelRoundAction({ shiftManagerId: values.id }));
    },
    [dispatch],
  );

  const onCloseTableVerifySubmit = useCallback(
    (values: { id: string }) => {
      dispatch(tableCloseVerifyAction({ shiftManagerId: values.id }));
    },
    [dispatch],
  );

  // const onShuffleSubmit = useCallback(
  //   (values: { id: string }) => {
  //     dispatch(shuffleAction({ shiftManagerId: values.id }));
  //   },
  //   [dispatch],
  // );

  const onShuffleSubmit = useCallback(
    (values: { id: string }) => {
      dispatch(shuffleAction({ shiftManagerId: values.id }));
    },
    [dispatch],
  );

  const onChangeResultSubmit = useCallback(
    (value: string) => {
      if (changeResult?.verified && changeResult?.shiftManagerId) {
        dispatch(
          changeResultAction({ shiftManagerId: changeResult.shiftManagerId, winner: value }),
        );
      }
    },
    [dispatch, changeResult],
  );

  const onCloseTableSubmit = useCallback(
    (value: TablesClosingStatus) => {
      if (closed?.verified && closed?.shiftManagerId) {
        dispatch(tableClosedAction());
        dispatch(tableCloseAction({ shiftManagerId: closed.shiftManagerId, closingStatus: value }));
      }
    },
    [dispatch, closed],
  );

  useEffect(() => {
    closeChangeDealerPopup();
  }, [dealer, closeChangeDealerPopup]);

  useEffect(() => {
    if (isCancelRoundPopupShown && tableState === TableStates.RoundCanceled) {
      toggleCancelRoundPopup();
    }
  }, [tableState, isCancelRoundPopupShown, toggleCancelRoundPopup]);

  useEffect(() => {
    if (tableState === TableStates.BettingTimeStarted) {
      reloadBettingTimer();
      startBettingTimer();
      setCardInput('');
    }
    if (tableState === TableStates.DrawStarted) {
      startDrawTimer();
    }
  }, [tableState, reloadBettingTimer, startBettingTimer, startDrawTimer]);

  useEffect(() => {
    if (
      tableState === TableStates.RoundFinished ||
      tableState === TableStates.RoundCanceled ||
      tableState === TableStates.BettingTimeFinished
    ) {
      reloadBettingTimer();
      setShuffleCrdShowed(false);
    }
    if (tableState === TableStates.DrawFinished || tableState === TableStates.RoundCanceled) {
      reloadDrawTimer();
    }
  }, [reloadBettingTimer, reloadDrawTimer, tableState]);

  useEffect(() => {
    if (changeResult?.verified) {
      toggleChangeResultVerifyPopup();
      toggleChangeResultPopup();
    }
  }, [changeResult?.verified, toggleChangeResultVerifyPopup, toggleChangeResultPopup]);

  useEffect(() => {
    if (closed?.verified) {
      toggleCloseTableVerifyPopup();
      toggleCloseTablePopup();
    }
  }, [closed?.verified, toggleCloseTableVerifyPopup, toggleCloseTablePopup]);

  useEffect(() => {
    if (closed?.verified && closed.isInProgress) {
      toggleCloseTablePopup();
    }
  }, [closed?.verified, closed?.isInProgress, toggleCloseTablePopup]);

  useEffect(() => {
    if (shuffle?.verified && shuffle.isInProgress) {
      toggleShufflePopup();
    }
  }, [shuffle, toggleShufflePopup]);

  useEffect(() => {
    if (changeResult?.verified && changeResult.isInProgress && changeResult.isChanged) {
      toggleChangeResultPopup();
    }
  }, [
    changeResult?.verified,
    changeResult?.isInProgress,
    changeResult?.isChanged,
    toggleChangeResultPopup,
  ]);

  useEffect(() => {
    if (isChangeDealerPopupShown) {
      dealerChangedClearError();
    }
    if (isCancelRoundPopupShown) {
      cancelRoundClearError();
    }
    if (isCloseTableVerifyPopupShown) {
      closeTableClearError();
    }
    if (isChangeResultVerifyPopupShown) {
      changeResultClearError();
    }
  }, [
    isChangeDealerPopupShown,
    isCancelRoundPopupShown,
    isCloseTableVerifyPopupShown,
    isChangeResultVerifyPopupShown,
    cancelRoundClearError,
    closeTableClearError,
    changeResultClearError,
    dealerChangedClearError,
  ]);

  useEffect(() => {
    const error = connectToWebSocketError as ICustomError;
    if (error?.message === 'Unauthorized' || error?.status === 404) {
      connectToWebSocketClearError();
      navigate(`/not_found`, { replace: true });
    }
  }, [connectToWebSocketError, connectToWebSocketClearError, navigate]);

  useEffect(() => {
    if (!webSocketService.isConnected() && urlTableId) {
      dispatch(clearSagaChannels());
      dispatch(
        connectToWebSocketAction({
          tableId: urlTableId,
          token: sessionStorage.getItem('access_to_ws_token')!,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [scannedText, setScannedText] = useState('');
  const [scannedTempText, setScannedTempText] = useState('');
  const [scannedCodes, setScannedCodes] = useState<string[]>([]);

  useEffect(() => {
    if (isScanTime) {
      setScannedCodes([]);
      setScannedText('');
    }
  }, [isScanTime]);

  const changeText = useCallback(
    (key: string) => {
      if (key === 'Shift' && !scannedText?.length) {
        return setScannedText(`${scannedText}${key}`);
      }
      if (key === 'Shift') {
        setScannedTempText(scannedTempText);
        return setScannedText(`${scannedText}${key}`);
      }
      if (key === 'Enter') {
        setScannedTempText('');
        setScannedCodes([...scannedCodes, scannedTempText]);
        dispatch(addNewCardEvent({ barcode: scannedTempText }));
        return setScannedText(`${scannedText}${key}`);
      }
      setScannedTempText(`${scannedTempText}${key}`);
      return setScannedText(`${scannedText}${key}`);
    },
    [scannedText, scannedTempText, scannedCodes, dispatch],
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        isScanTime &&
        !isBurnCardWindow &&
        !isCancelRoundPopupShown &&
        !isCloseTableVerifyPopupShown &&
        !isChangeResultVerifyPopupShown &&
        !isCloseTablePopupShown
      ) {
        changeText(event.key);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    changeText,
    isScanTime,
    isBurnCardWindow,
    isCancelRoundPopupShown,
    isCloseTableVerifyPopupShown,
    isChangeResultVerifyPopupShown,
    isCloseTablePopupShown,
  ]);

  const [cardInput, setCardInput] = useState<any>('');

  const finishRound = () => {
    dispatch(finishRoundEvent());
  };

  useEffect(() => {
    if (hasUndefinedCard && joker_high_low && !joker) {
      setJokerCardTime(true);
    }
  }, [hasUndefinedCard, joker_high_low, joker]);

  useEffect(() => {
    if (!shuffleCrdShowed && shuffleRequired && tableState === TableStates.BettingTimeStarted) {
      setShuffleCrdShowed(true);
      dispatch(setIsShuffleWindowToStore(true));
    }
    if (bettingTimer === 3 && burnCardRequired && tableState === TableStates.BettingTimeStarted) {
      dispatch(setIsBurnCardWindowToStore(true));
    }
  }, [bettingTimer, burnCardRequired, shuffleRequired, shuffleCrdShowed, tableState, dispatch]);

  const cancelBurnCard = () => {
    dispatch(setIsBurnCardWindowToStore(false));
  };

  const cancelShuffle = () => {
    dispatch(setIsShuffleWindowToStore(false));
  };

  const onJokerCardRoundClick = () => {
    setJokerCardTime(true);
    dispatch(setIsScanTime(true));
    dispatch(setCardsStatusToStore(gameCardsStatus.ScanJoker));
    dispatch(setIsJokerCardRound(true));
    dispatch(changeGameStatusAction({ status: '' }));
  };

  const addCard = () => {
    setScannedCodes([...scannedCodes, cardInput]);
    setCardInput('');
    if (jokerCardTime) {
      dispatch(addNewJokerCardEvent({ barcode: cardInput }));
      setJokerCardTime(false);
      return;
    }
    dispatch(addNewCardEvent({ barcode: cardInput }));
  };

  const isActiveShuffle = Boolean(
    (tableState === TableStates.TableOpened ||
      tableState === TableStates.RoundFinished ||
      tableState === TableStates.RoundCanceled) &&
      joker,
  );

  return (
    <Wrapper>
      {isChangeDealerPopupShown
        ? createPortal(
            <PopupVerification
              title="Change dealer"
              type={PopupVerificationTypes.Dealer}
              error={dealerChangedError}
              loading={changeDealerLoading}
              clearError={dealerChangedClearError}
              onCancel={closeChangeDealerPopup}
              onSubmit={onChangeDealer}
            />,
            document.body,
          )
        : null}
      {isCancelRoundPopupShown
        ? createPortal(
            <PopupVerification
              title="Cancel round"
              type={PopupVerificationTypes.ShiftManager}
              error={cancelRoundError}
              loading={cancelRoundLoading}
              clearError={cancelRoundClearError}
              onCancel={toggleCancelRoundPopup}
              onSubmit={onCancelRoundSubmit}
            />,
            document.body,
          )
        : null}
      {isCloseTableVerifyPopupShown
        ? createPortal(
            <PopupVerification
              title="Close table"
              error={closeTableError}
              loading={closeTableVerifyLoading}
              type={PopupVerificationTypes.ShiftManager}
              clearError={closeTableClearError}
              onCancel={toggleCloseTableVerifyPopup}
              onSubmit={onCloseTableVerifySubmit}
            />,
            document.body,
          )
        : null}
      {isShufflePopupShown
        ? createPortal(
            <PopupVerification
              title="Shuffle"
              error={shuffleError}
              loading={shuffleLoading}
              type={PopupVerificationTypes.ShiftManager}
              clearError={shuffleClearError}
              onCancel={toggleShufflePopup}
              onSubmit={onShuffleSubmit}
            />,
            document.body,
          )
        : null}
      {isCloseTablePopupShown
        ? createPortal(
            <PopupClosingTable
              options={tableClosingOptionsMock}
              loading={closeTableLoading}
              onClose={toggleCloseTablePopup}
              onSubmit={onCloseTableSubmit}
            />,
            document.body,
          )
        : null}
      {isChangeResultPopupShown
        ? createPortal(
            <PopupChangeResult
              options={ballOptions}
              loading={changeResultLoading}
              onClose={toggleChangeResultPopup}
              onSubmit={onChangeResultSubmit}
            />,
            document.body,
          )
        : null}
      <TableHeader>
        <TableHeadStyled
          tableId={tableId}
          gameId={gameId}
          dealerName={dealer?.nickname}
          isActiveShuffle={isActiveShuffle}
          isAvailableShuffle={joker_high_low}
          isChangeDealerDisabled={!isChangeDealerEnabled}
          onChangeDealerClick={openChangeDealerPopup}
          onCloseTableClick={toggleCloseTableVerifyPopup}
          onShuffleClick={toggleShufflePopup}
        />
      </TableHeader>
      <ContentWrapper>
        <ControlsWrapper>
          <GameProvider>{game_type}</GameProvider>
          <TableStatusInfoStyled
            timeValue={timeValue || bettingTimer || drawTimer}
            isFinished={isFinished}
            statusText={statusText}
            status={tableState}
            cardsStatus={cardsStatus}
          />
          <TableControlsStyled
            onOpenRoundClick={onOpenRoundClick}
            onJokerCardRoundClick={onJokerCardRoundClick}
            openRoundLoading={false}
            onConfirmResultClick={finishRound}
            confirmResultLoading={confirmResultLoading}
            onCancelRoundClick={onCancelRoundClick}
            isStartRound={isStartRound}
            isRoundEnd={isRoundEnd}
            isCancelRound={isCancelRound}
            isJokerCardRound={isJokerCardRound}
            isJokerCard={joker_high_low}
          />
          {toastItem ? (
            <CloseNotificationWrapper>
              <Alert variant={toastItem.type ? toastItem.type : ToastTypes.WARNING} showIcon>
                {toastItem.value.text}
              </Alert>
            </CloseNotificationWrapper>
          ) : null}
        </ControlsWrapper>
        <ViewWrapper>
          {cards?.length || hasUndefinedCard || joker_high_low ? (
            <DrawResult
              cards={cards}
              joker={joker}
              winner={winner}
              hasDoubleCard={hasDoubleCard}
              hasUndefinedCard={hasUndefinedCard}
              isAvailableShuffle={joker_high_low}
            />
          ) : null}
        </ViewWrapper>
      </ContentWrapper>
      <Line />
      {isScanTime && !isBurnCardWindow && !isShuffleWindow && (
        <ContentWrapper>
          <BarcodeForm>
            <Input
              label="Barcode"
              hasError={false}
              message=""
              name="Barcode"
              placeholder="Enter barcode"
              value={cardInput}
              onChange={(event: any) => setCardInput(event.target.value)}
            />
            <BarcodeFormButton>
              <Button onClick={addCard}>Add card</Button>
            </BarcodeFormButton>
          </BarcodeForm>
          <div>
            <Block>
              <BlockCards>Scanned barcodes:</BlockCards> {scannedCodes?.map((code) => `${code}, `)}
            </Block>
          </div>
        </ContentWrapper>
      )}

      {(isBurnCardWindow || isShuffleWindow) && (
        <WindowBurnCard>
          {isBurnCardWindow && (
            <WindowBurnCardContent backgroundColor="#155878" borderColor="#3F94BD">
              <BurnCardText>Burn Card</BurnCardText>
              <ButtonWrapper>
                <Button onClick={cancelBurnCard} size={ButtonSizes.sm}>
                  Close
                </Button>
              </ButtonWrapper>
            </WindowBurnCardContent>
          )}
          {isShuffleWindow && (
            // <WindowBurnCard>
            <WindowBurnCardContent backgroundColor="#305B20" borderColor="#3C8F1E">
              <BurnCardText>Shuffle</BurnCardText>
              <ButtonWrapper>
                <Button onClick={cancelShuffle} size={ButtonSizes.sm}>
                  Close
                </Button>
              </ButtonWrapper>
            </WindowBurnCardContent>
            // </WindowBurnCard>
          )}
        </WindowBurnCard>
      )}
    </Wrapper>
  );
});
