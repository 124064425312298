import { DealerType, ICard, TableCommonType, TableStates } from 'types';

export enum Notifications {
  MESSAGE = 'MESSAGE',
  DEALER_CONNECTED = 'DEALER_CONNECTED',
  OPEN_TABLE = 'OPEN_TABLE',
  TABLE_OPENED = 'TABLE_OPENED',
  TABLE_CLOSE_VERIFY = 'VERIFY_SHIFT_MANAGER',
  TABLE_CLOSE_VERIFIED = 'VERIFY_SHIFT_MANAGER',
  VERIFY_SHIFT_MANAGER = 'VERIFY_SHIFT_MANAGER',
  CLOSE_TABLE = 'CLOSE_TABLE',
  TABLE_CLOSED = 'TABLE_CLOSED',
  CHANGE_DEALER = 'CHANGE_DEALER',
  DEALER_CHANGED = 'DEALER_CHANGED',
  START_ROUND = 'START_ROUND',
  ROUND_STARTED = 'ROUND_STARTED',
  CANCEL_ROUND = 'CANCEL_ROUND',
  ROUND_CANCELED = 'ROUND_CANCELED',
  BETTING_TIME_STARTED = 'BETTING_TIME_STARTED',
  BETTING_TIME_FINISHED = 'BETTING_TIME_FINISHED',
  START_DRAW = 'START_DRAW',
  DRAW_STARTED = 'DRAW_STARTED',
  DRAW_FINISHED = 'DRAW_FINISHED',
  CONFIRM_RESULT = 'CONFIRM_RESULT',
  RESULT_CONFIRMED = 'RESULT_CONFIRMED',
  ROUND_FINISHED = 'ROUND_FINISHED',
  CHANGE_RESULT = 'CHANGE_RESULT',
  RESULT_CHANGED = 'RESULT_CHANGED',
  GAME_MACHINE_CONNECTION = 'GAME_MACHINE_CONNECTION',
  NOTIFICATION = 'NOTIFICATION',
  PONG = 'PONG',
  FINISH_ROUND = 'FINISH_ROUND',
  ADD_NEW_CARD = 'ADD_NEW_CARD',
  NEW_CARD_ADDED = 'NEW_CARD_ADDED',
  WAITING_NEXT_CARD = 'WAITING_NEXT_CARD',
  ERROR = 'ERROR',
  CHANGE_JOKER = 'CHANGE_JOKER',
  JOKER_CHANGED = 'JOKER_CHANGED',
  SHUFFLE = 'SHUFFLE',
}

export enum ClientSideNotifications {
  SOCKET_CONNECTED = 'connect',
  SOCKET_DISCONNECTED = 'disconnect',
}

export enum ErrorNotifications {
  CONNECT_ERROR = 'connect_error',
  ERROR = 'error',
  ERROR_CARDS = 'ERROR',
}

export interface INotificationData {
  code: string;
  message: string;
  payload: object;
}

export interface INotificationResponse<T> {
  data: T;
  type: Notifications;
}

export interface IDataResponse {
  status: number;
  message: string;
}

interface IDealerJoinedResponseData {
  tableId: string;
  state?: {
    dealer: DealerType;
    joker: ICard | null;
    last_balls: string[];
    status: TableStates;
    round?: {
      gameId: string;
      drawTime?: number;
      winner?: string;
      drawStartedAt?: string;
      startedAt?: string;
      gameCards?: ICard[];
      burnCardRequired?: boolean;
      finishBettingTime?: string;
      shuffleRequired?: boolean;
    };
    table: TableCommonType;
  };
}

interface ITableOpenedResponseData {
  state: TableCommonType;
  gameState: {
    dealer: DealerType;
    joker: ICard | null;
    last_balls: string[];
    status: TableStates;
    round?: {
      gameId: string;
      drawTime?: number;
      winner?: string;
      drawStartedAt?: string;
      startedAt?: string;
      gameCards?: ICard[];
      burnCardRequired?: boolean;
      finishBettingTime?: string;
      shuffleRequired?: boolean;
    };
    table: TableCommonType;
  };
}

interface ITableCloseVerifyResponseData {}

interface ITableClosedResponseData {}

interface IDealerChangedResponseData {
  dealer: DealerType;
}

interface IRoundStartedResponseData {
  round: {
    gameId: string;
  };
}

interface ISocketPongData {
  token: string;
}

interface IRoundCanceledResponseData {
  fromAdmin: boolean;
}

interface IBettingTimeStartedResponseData {
  bettingTime: number;
  additional: boolean;
  burnCardRequired: boolean;
  shuffleRequired: boolean;
}

interface IBettingTimeFinishedResponseData {}

interface IDrawStartedResponseData {
  state: {
    dealer: DealerType;
    last_balls: string[];
    status: TableStates;
    round?: {
      gameId: string;
      drawTime?: number;
      winner?: string;
      drawStartedAt?: string;
      startedAt?: string;
    };
    table: TableCommonType;
  };
}

interface IDrawFinishedResponseData {
  winner: string;
  tableId: number;
}

interface IResultConfirmedResponseData {
  winner: string;
}
interface IResultChangedResponseData {}

interface IRoundFinishedResponseData {}

interface IGameMachineConnectionResponseResponseData {
  status: 'CONNECTED' | 'DISCONNECTED';
}

interface IAddedCardResponseData {
  tableId: string;
  card: ICard;
}

export type DealerJoinedResponse = INotificationResponse<IDealerJoinedResponseData>;
export type TableOpenedResponse = INotificationResponse<ITableOpenedResponseData>;
export type TableCloseVerifyResponse = INotificationResponse<ITableCloseVerifyResponseData>;
export type TableClosedResponse = INotificationResponse<ITableClosedResponseData>;
export type DealerChangedResponse = INotificationResponse<IDealerChangedResponseData>;
export type RoundStartedResponse = INotificationResponse<IRoundStartedResponseData>;
export type RoundCanceledResponse = INotificationResponse<IRoundCanceledResponseData>;
export type BettingTimeStartedResponse = INotificationResponse<IBettingTimeStartedResponseData>;
export type BettingTimeFinishedResponse = INotificationResponse<IBettingTimeFinishedResponseData>;
export type DrawStartedResponse = INotificationResponse<IDrawStartedResponseData>;
export type DrawFinishedResponse = INotificationResponse<IDrawFinishedResponseData>;
export type ResultConfirmedResponse = INotificationResponse<IResultConfirmedResponseData>;
export type ResultChangedResponse = INotificationResponse<IResultChangedResponseData>;
export type RoundFinishedResponse = INotificationResponse<IRoundFinishedResponseData>;
export type GameMachineConnectionResponse =
  INotificationResponse<IGameMachineConnectionResponseResponseData>;
export type SocketPongResponse = INotificationResponse<ISocketPongData>;
export type AddedCardResponse = INotificationResponse<IAddedCardResponseData>;

export type PossibleResponse =
  | DealerJoinedResponse
  | TableOpenedResponse
  | TableCloseVerifyResponse
  | TableClosedResponse
  | DealerChangedResponse
  | RoundStartedResponse
  | RoundCanceledResponse
  | BettingTimeStartedResponse
  | BettingTimeFinishedResponse
  | DrawStartedResponse
  | DrawFinishedResponse
  | ResultConfirmedResponse
  | RoundFinishedResponse;
