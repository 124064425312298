import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { ICard, TableCommonType } from 'types';

import { ACTIONS } from '../constants';

export interface ITableState {
  tablesData: TableCommonType[];
  selectedTableData: TableCommonType | null;
  isStartRound: boolean;
  isBettingTime: boolean;
  isScanTime: boolean;
  isRoundEnd: boolean;
  isCancelRound: boolean;
  cards: ICard[];
  winner: string | null;
  isBurnCardWindow: boolean;
  isShuffleWindow: boolean;
  isJokerCardRound: boolean;
  cardsStatus: string | null;
  hasUndefinedCard: boolean;
  hasDoubleCard: boolean;
  joker: null | ICard;
}

export const tableState: ITableState = {
  tablesData: [],
  selectedTableData: null,
  isStartRound: false,
  isBettingTime: false,
  isScanTime: false,
  isRoundEnd: false,
  isCancelRound: false,
  cards: [],
  winner: null,
  isBurnCardWindow: false,
  isShuffleWindow: false,
  isJokerCardRound: false,
  cardsStatus: null,
  hasUndefinedCard: false,
  hasDoubleCard: false,
  joker: null,
};

export const getTablesRequest = createAction(ACTIONS.GET_TABLES_REQUEST)();
export const getTablesSuccess = createAction(ACTIONS.GET_TABLES_SUCCESS)();
export const getTablesFailure = createAction(ACTIONS.GET_TABLES_FAILURE)<Error>();

export const getTableRequest = createAction(ACTIONS.GET_TABLE_REQUEST)<{ id: string }>();
export const getTableSuccess = createAction(ACTIONS.GET_TABLE_SUCCESS)();
export const getTableFailure = createAction(ACTIONS.GET_TABLE_FAILURE)<Error>();

export const saveTablesToStore = createAction(ACTIONS.SAVE_TABLES_TO_STORE)<{
  tablesData: TableCommonType[];
}>();

export const saveSelectedTableToStore = createAction(ACTIONS.SAVE_SELECTED_TABLE_TO_STORE)<{
  selectedTableData: TableCommonType;
}>();
export const clearSelectedTableToStore = createAction(ACTIONS.CLEAR_SELECTED_TABLE_TO_STORE)();

export const setIsStartRound = createAction(ACTIONS.SET_IS_START_ROUND)<boolean>();
export const setIsBettingTime = createAction(ACTIONS.SET_IS_BETTING_TIME)<boolean>();
export const setIsScanTime = createAction(ACTIONS.SET_IS_SCAN_TIME)<boolean>();
export const setIsRoundEnd = createAction(ACTIONS.SET_IS_ROUND_END)<boolean>();
export const setIsJokerCardRound = createAction(ACTIONS.SET_IS_JOKER_CARD_ROUND)<boolean>();
export const setIsCancelRound = createAction(ACTIONS.SET_IS_CANCEL_ROUND)<boolean>();
export const addNewCardEvent = createAction(ACTIONS.ADD_NEW_CARD)<{ barcode: string }>();
export const addNewJokerCardEvent = createAction(ACTIONS.ADD_NEW_JOKER_CARD)<{
  barcode: string;
}>();
export const finishRoundEvent = createAction(ACTIONS.FINISH_ROUND)();
export const addCardToStore = createAction(ACTIONS.ADD_CARD_TO_STORE)<ICard>();
export const clearCardsToStore = createAction(ACTIONS.CLEAR_CARDS_TO_STORE)();
export const setCardsToStore = createAction(ACTIONS.SET_CARDS_TO_STORE)<ICard[]>();
export const setWinnerToStore = createAction(ACTIONS.SET_WINNER_TO_STORE)<string | null>();
export const setIsBurnCardWindowToStore = createAction(
  ACTIONS.SET_IS_BURN_CARD_WINDOW_TO_STORE,
)<boolean>();
export const setIsShuffleWindowToStore = createAction(
  ACTIONS.SET_IS_SHUFFLE_CARD_WINDOW_TO_STORE,
)<boolean>();
export const setCardsStatusToStore = createAction(ACTIONS.SET_CARDS_STATUS_TO_STORE)<
  string | null
>();
export const setHasUndefinedCardToStore = createAction(ACTIONS.SET_HAS_UNDEFINED_CARD)<boolean>();
export const setHasDoubleCardToStore = createAction(ACTIONS.SET_HAS_DOUBLE_CARD)<boolean>();
export const setJokerCardToStore = createAction(ACTIONS.SET_JOKER)<ICard | null>();

const actions = {
  saveTablesToStore,
  saveSelectedTableToStore,
  clearSelectedTableToStore,
  setIsStartRound,
  setIsBettingTime,
  setIsScanTime,
  setIsRoundEnd,
  setIsCancelRound,
  addCardToStore,
  clearCardsToStore,
  setCardsToStore,
  setWinnerToStore,
  setIsBurnCardWindowToStore,
  setIsShuffleWindowToStore,
  setCardsStatusToStore,
  setHasUndefinedCardToStore,
  setHasDoubleCardToStore,
  setJokerCardToStore,
  setIsJokerCardRound,
};

export const tablesReducer = createReducer<ITableState, ActionType<typeof actions>>(tableState)
  .handleAction(saveTablesToStore, (state, { payload: { tablesData } }) => ({
    ...state,
    tablesData,
  }))
  .handleAction(saveSelectedTableToStore, (state, { payload: { selectedTableData } }) => ({
    ...state,
    selectedTableData,
  }))
  .handleAction(clearSelectedTableToStore, (state) => ({
    ...state,
    selectedTableData: null,
  }))
  .handleAction(setIsStartRound, (state, { payload: isStartRound }) => ({
    ...state,
    isStartRound,
  }))
  .handleAction(setIsBettingTime, (state, { payload: isBettingTime }) => ({
    ...state,
    isBettingTime,
  }))
  .handleAction(setIsScanTime, (state, { payload: isScanTime }) => ({
    ...state,
    isScanTime,
  }))
  .handleAction(setIsRoundEnd, (state, { payload: isRoundEnd }) => ({
    ...state,
    isRoundEnd,
  }))
  .handleAction(setIsCancelRound, (state, { payload: isCancelRound }) => ({
    ...state,
    isCancelRound,
  }))
  .handleAction(addCardToStore, (state, { payload: card }) => ({
    ...state,
    cards: [...state.cards, card],
  }))
  .handleAction(clearCardsToStore, (state) => ({
    ...state,
    cards: [],
  }))
  .handleAction(setCardsToStore, (state, { payload: cards }) => ({
    ...state,
    cards,
  }))
  .handleAction(setWinnerToStore, (state, { payload: winner }) => ({
    ...state,
    winner,
  }))
  .handleAction(setIsBurnCardWindowToStore, (state, { payload: isBurnCardWindow }) => ({
    ...state,
    isBurnCardWindow,
  }))
  .handleAction(setIsShuffleWindowToStore, (state, { payload: isShuffleWindow }) => ({
    ...state,
    isShuffleWindow,
  }))
  .handleAction(setCardsStatusToStore, (state, { payload: cardsStatus }) => ({
    ...state,
    cardsStatus,
  }))
  .handleAction(setHasUndefinedCardToStore, (state, { payload: hasUndefinedCard }) => ({
    ...state,
    hasUndefinedCard,
  }))
  .handleAction(setHasDoubleCardToStore, (state, { payload: hasDoubleCard }) => ({
    ...state,
    hasDoubleCard,
  }))
  .handleAction(setJokerCardToStore, (state, { payload: joker }) => ({
    ...state,
    joker,
  }))
  .handleAction(setIsJokerCardRound, (state, { payload: isJokerCardRound }) => ({
    ...state,
    isJokerCardRound,
  }));
