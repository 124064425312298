import styled from 'styled-components';
import fontService, { FontWeight } from 'services/FontService';
import { Input } from 'components/atoms';

export const Form = styled.form`
  width: 400px;
`;

export const KeypadWrapper = styled.div`
  margin-top: 24px;
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 222px 160px;
  column-gap: 16px;
  justify-content: space-between;
  margin-top: 32px;
`;

export const ErrorMessage = styled.div`
  width: 100%;
  margin-top: 16px;
  padding: 8px 12px;
  background: ${({ theme: { errorBGColor } }) => errorBGColor};
  border-radius: 4px;
  ${fontService.text({ size: 20, lineHeight: 140, weight: FontWeight.regular })};
  color: ${({ theme: { primaryTextColor } }) => primaryTextColor};
  letter-spacing: 0.02em;
`;

export const InputStyled = styled(Input)`
  & input {
    font-size: 32px;
    line-height: 150%;
    font-weight: 600;
  }
`;
