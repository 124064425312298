import React from 'react';
import { IconNames } from '../Icon';
import { ButtonIcon, ButtonStyled, ButtonText } from './styles';
import { ButtonPreloader } from '../Preloader';

export interface IProps {
  children: string;
  iconName: IconNames;
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
  className?: string;
  isSmallSize: boolean;
  width?: number;
}

export const ButtonControl = ({
  children,
  iconName,
  disabled = false,
  loading = false,
  onClick,
  className,
  isSmallSize,
  width,
}: IProps) => (
  <ButtonStyled
    isSmallSize={isSmallSize}
    onClick={onClick}
    disabled={disabled}
    className={className}
    width={width}>
    {loading ? (
      <ButtonPreloader />
    ) : (
      <>
        <ButtonIcon isSmallSize={isSmallSize} name={iconName} />
        <ButtonText isSmallSize={isSmallSize}>{children}</ButtonText>
      </>
    )}
  </ButtonStyled>
);
