import { takeEvery, call, cancelled, fork, take, cancel } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { webSocketService } from 'services/WebSocketService';
import {
  Notifications,
  ClientSideNotifications,
  ErrorNotifications,
  DealerChangedResponse,
  RoundStartedResponse,
  DealerJoinedResponse,
  TableOpenedResponse,
  DrawStartedResponse,
  BettingTimeStartedResponse,
  BettingTimeFinishedResponse,
  DrawFinishedResponse,
  ResultConfirmedResponse,
  RoundCanceledResponse,
  RoundFinishedResponse,
  TableClosedResponse,
  ResultChangedResponse,
  GameMachineConnectionResponse,
  TableCloseVerifyResponse,
  SocketPongResponse,
  AddedCardResponse,
} from 'types';

import { connectToWebSocketAction } from '../reducers';
import { ACTIONS } from '../constants';
import {
  dealerJoined,
  tableOpened,
  roundStarted,
  bettingTimeStarted,
  bettingTimeFinished,
  drawStarted,
  roundCanceled,
  dealerChanged,
  drawFinished,
  resultConfirmed,
  resultChanged,
  roundFinished,
  tableClosed,
  gameMachineConnection,
  tableCloseNotification,
  onSocketConnectedSaga,
  onSocketDisconnectedSaga,
  onSocketErrorSaga,
  socketPongSaga,
  addedNewCard,
  waitingNextCard,
  changeJokerCard,
} from './notificationHandlers';
import {
  openTableSaga,
  startRoundSaga,
  startDrawSaga,
  cancelRoundSaga,
  changeDealerSaga,
  confirmResultSaga,
  closeTableSaga,
  verifyCloseTableSaga,
  verifyChangeResultSaga,
  changeResultSaga,
  finishRoundSaga,
  addNewCardSaga,
  addNewJokerCardSaga,
  shuffleSaga,
} from './notificationRequest';
import {
  createWebSocketChannel,
  simulateWebSocketChannel,
  errorWebSocketChannel,
} from './channels';

function* listenchangeJokerCardMessages(): any {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.JOKER_CHANGED]);

  try {
    yield takeEvery(notificationChannel, function* (notification: AddedCardResponse) {
      yield call(changeJokerCard, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenAddedNewCardMessages(): any {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.NEW_CARD_ADDED]);

  try {
    yield takeEvery(notificationChannel, function* (notification: AddedCardResponse) {
      yield call(addedNewCard, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenWaitingNextCardMessages(): any {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.WAITING_NEXT_CARD]);

  try {
    yield takeEvery(notificationChannel, function* (notification: any) {
      yield call(waitingNextCard, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

// ------- end listenStage

function* listenWebSocketMessages(): any {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.DEALER_CONNECTED]);

  try {
    yield takeEvery(notificationChannel, function* (notification: DealerJoinedResponse) {
      yield call(dealerJoined, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenTableOpenedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.TABLE_OPENED]);

  try {
    yield takeEvery(notificationChannel, function* (notification: TableOpenedResponse) {
      yield call(tableOpened, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenTableClosedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.TABLE_CLOSED]);

  try {
    yield takeEvery(notificationChannel, function* (notification: TableClosedResponse) {
      yield call(tableClosed, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenDealerChangedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.DEALER_CHANGED]);

  try {
    yield takeEvery(notificationChannel, function* (notification: DealerChangedResponse) {
      yield call(dealerChanged, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenRoundStartedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.ROUND_STARTED]);

  try {
    yield takeEvery(notificationChannel, function* (notification: RoundStartedResponse) {
      yield call(roundStarted, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenRoundCanceledMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.ROUND_CANCELED]);

  try {
    yield takeEvery(notificationChannel, function* (notification: RoundCanceledResponse) {
      yield call(roundCanceled, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenBettingTimeStartedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [
    Notifications.BETTING_TIME_STARTED,
  ]);

  try {
    yield takeEvery(notificationChannel, function* (notification: BettingTimeStartedResponse) {
      yield call(bettingTimeStarted, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenBettingTimeFinishedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [
    Notifications.BETTING_TIME_FINISHED,
  ]);

  try {
    yield takeEvery(notificationChannel, function* (notification: BettingTimeFinishedResponse) {
      yield call(bettingTimeFinished, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenDrawStartedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.DRAW_STARTED]);

  try {
    yield takeEvery(notificationChannel, function* (notification: DrawStartedResponse) {
      yield call(drawStarted, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenDrawFinishedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.DRAW_FINISHED]);

  try {
    yield takeEvery(notificationChannel, function* (notification: DrawFinishedResponse) {
      yield call(drawFinished, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenResultConfirmedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.RESULT_CONFIRMED]);

  try {
    yield takeEvery(notificationChannel, function* (notification: ResultConfirmedResponse) {
      yield call(resultConfirmed, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenResultChangedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.RESULT_CHANGED]);

  try {
    yield takeEvery(notificationChannel, function* (notification: ResultChangedResponse) {
      yield call(resultChanged, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenRoundFinishedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.ROUND_FINISHED]);

  try {
    yield takeEvery(notificationChannel, function* (notification: RoundFinishedResponse) {
      yield call(roundFinished, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenGameMachineConnectionMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [
    Notifications.GAME_MACHINE_CONNECTION,
  ]);

  try {
    yield takeEvery(notificationChannel, function* (notification: GameMachineConnectionResponse) {
      yield call(gameMachineConnection, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenTableCloseNotificationMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.NOTIFICATION]);

  try {
    yield takeEvery(notificationChannel, function* (notification: TableCloseVerifyResponse) {
      yield call(tableCloseNotification, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenSocketPongMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.PONG]);

  try {
    yield takeEvery(notificationChannel, function* (notification: SocketPongResponse) {
      yield call(socketPongSaga, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenSocketConnectedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(simulateWebSocketChannel, [
    ClientSideNotifications.SOCKET_CONNECTED,
  ]);

  try {
    yield takeEvery(notificationChannel, function* (notification: string) {
      yield call(onSocketConnectedSaga, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenSocketDisconnectedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(simulateWebSocketChannel, [
    ClientSideNotifications.SOCKET_DISCONNECTED,
  ]);

  try {
    yield takeEvery(notificationChannel, function* (notification: string) {
      yield call(onSocketDisconnectedSaga, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenSocketErrorMessage() {
  // @ts-ignore
  const notificationChannel = yield call(errorWebSocketChannel, [ErrorNotifications.CONNECT_ERROR]);

  try {
    yield takeEvery(notificationChannel, function* (notification: string) {
      yield call(onSocketErrorSaga, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

export function* connectToWebSocket({
  payload: { tableId, token },
}: ActionType<typeof connectToWebSocketAction>) {
  // @ts-ignore
  if (!(yield call(() => webSocketService.isConnected()))) {
    yield call(() => webSocketService.connect(tableId, token));

    // @ts-ignore
    const taskDealerConnected = yield fork(listenWebSocketMessages);
    // @ts-ignore
    const taskTableOpened = yield fork(listenTableOpenedMessage);
    // @ts-ignore
    const taskTableClosed = yield fork(listenTableClosedMessage);
    // @ts-ignore
    const taskDealerChanged = yield fork(listenDealerChangedMessage);
    // @ts-ignore
    const taskRoundStarted = yield fork(listenRoundStartedMessage);
    // @ts-ignore
    const taskRoundCanceled = yield fork(listenRoundCanceledMessage);
    // @ts-ignore
    const taskBettingTimeStarted = yield fork(listenBettingTimeStartedMessage);
    // @ts-ignore
    const taskBettingTimeFinished = yield fork(listenBettingTimeFinishedMessage);
    // @ts-ignore
    const taskDrawStarted = yield fork(listenDrawStartedMessage);
    // @ts-ignore
    const taskDrawFinished = yield fork(listenDrawFinishedMessage);
    // @ts-ignore
    const taskResultConfirmed = yield fork(listenResultConfirmedMessage);
    // @ts-ignore
    const taskResultChanged = yield fork(listenResultChangedMessage);
    // @ts-ignore
    const taskRoundFinished = yield fork(listenRoundFinishedMessage);
    // @ts-ignore
    const taskGameMachineConnection = yield fork(listenGameMachineConnectionMessage);
    // @ts-ignore
    const taskTableCloseNotification = yield fork(listenTableCloseNotificationMessage);
    // @ts-ignore
    const taskSocketConnected = yield fork(listenSocketConnectedMessage);
    // @ts-ignore
    const taskSocketDisconnected = yield fork(listenSocketDisconnectedMessage);
    // @ts-ignore
    const taskSocketError = yield fork(listenSocketErrorMessage);
    // @ts-ignore
    const taskSocketPong = yield fork(listenSocketPongMessage);
    // @ts-ignore
    const taskAddedNewCard = yield fork(listenAddedNewCardMessages);
    // @ts-ignore
    const taskWaitingNextCard = yield fork(listenWaitingNextCardMessages);

    // @ts-ignore
    const taskchangeJokerCard = yield fork(listenchangeJokerCardMessages);

    yield take(ACTIONS.CLEAR_SAGA_CHANNELS);
    yield call(() => webSocketService.disconnect());
    yield cancel(taskDealerConnected);
    yield cancel(taskTableOpened);
    yield cancel(taskTableClosed);
    yield cancel(taskDealerChanged);
    yield cancel(taskRoundStarted);
    yield cancel(taskRoundCanceled);
    yield cancel(taskBettingTimeStarted);
    yield cancel(taskBettingTimeFinished);
    yield cancel(taskDrawStarted);
    yield cancel(taskDrawFinished);
    yield cancel(taskResultConfirmed);
    yield cancel(taskResultChanged);
    yield cancel(taskRoundFinished);
    yield cancel(taskGameMachineConnection);
    yield cancel(taskTableCloseNotification);
    yield cancel(taskSocketConnected);
    yield cancel(taskSocketDisconnected);
    yield cancel(taskSocketError);
    yield cancel(taskSocketPong);
    yield cancel(taskAddedNewCard);
    yield cancel(taskWaitingNextCard);
    yield cancel(taskchangeJokerCard);
  }
}

export function* startListeningWebSocketMessagesSaga() {
  yield takeEvery(ACTIONS.CONNECT_TO_WEBSOCKET, connectToWebSocket);
  yield takeEvery(ACTIONS.OPEN_TABLE, openTableSaga);
  yield takeEvery(ACTIONS.CHANGE_DEALER, changeDealerSaga);
  yield takeEvery(ACTIONS.START_ROUND, startRoundSaga);
  yield takeEvery(ACTIONS.CANCEL_ROUND, cancelRoundSaga);
  yield takeEvery(ACTIONS.START_DRAW, startDrawSaga);
  yield takeEvery(ACTIONS.CONFIRM_RESULT, confirmResultSaga);
  yield takeEvery(ACTIONS.CLOSE_TABLE_VERIFY, verifyCloseTableSaga);
  yield takeEvery(ACTIONS.CLOSE_TABLE, closeTableSaga);
  yield takeEvery(ACTIONS.CHANGE_RESULT_VERIFY, verifyChangeResultSaga);
  yield takeEvery(ACTIONS.CHANGE_RESULT, changeResultSaga);
  yield takeEvery(ACTIONS.FINISH_ROUND, finishRoundSaga);
  yield takeEvery(ACTIONS.ADD_NEW_CARD, addNewCardSaga);
  yield takeEvery(ACTIONS.ADD_NEW_JOKER_CARD, addNewJokerCardSaga);
  yield takeEvery(ACTIONS.SHUFFLE_JOKER, shuffleSaga);
}
