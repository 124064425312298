import React from 'react';
import * as Icons from './Icons';

export enum IconNames {
  Person = 'Person',
  People = 'People',
  Power = 'Power',
  Shuffle = 'Shuffle',
  Check = 'Check',
  CheckCircle = 'CheckCircle',
  SmartRecord = 'SmartRecord',
  Replay = 'Replay',
  Clear = 'Clear',
  CloseEye = 'CloseEye',
  OpenEye = 'OpenEye',
  ArrowRight = 'ArrowRight',
  NoAvailable = 'NoAvailable',
  Warning = 'Warning',
  LoadingIndicator = 'LoadingIndicator',
  NoAvailablePage = 'NoAvailablePage',

  clubs_2 = 'clubs_2',
  clubs_3 = 'clubs_3',
  clubs_4 = 'clubs_4',
  clubs_5 = 'clubs_5',
  clubs_6 = 'clubs_6',
  clubs_7 = 'clubs_7',
  clubs_8 = 'clubs_8',
  clubs_9 = 'clubs_9',
  clubs_10 = 'clubs_10',
  clubs_ace = 'clubs_ace',
  clubs_jack = 'clubs_jack',
  clubs_king = 'clubs_king',
  clubs_queen = 'clubs_queen',

  hearts_2 = 'hearts_2',
  hearts_3 = 'hearts_3',
  hearts_4 = 'hearts_4',
  hearts_5 = 'hearts_5',
  hearts_6 = 'hearts_6',
  hearts_7 = 'hearts_7',
  hearts_8 = 'hearts_8',
  hearts_9 = 'hearts_9',
  hearts_10 = 'hearts_10',
  hearts_ace = 'hearts_ace',
  hearts_jack = 'hearts_jack',
  hearts_queen = 'hearts_queen',
  hearts_king = 'hearts_king',

  spades_2 = 'spades_2',
  spades_3 = 'spades_3',
  spades_4 = 'spades_4',
  spades_5 = 'spades_5',
  spades_6 = 'spades_6',
  spades_7 = 'spades_7',
  spades_8 = 'spades_8',
  spades_9 = 'spades_9',
  spades_10 = 'spades_10',
  spades_ace = 'spades_ace',
  spades_jack = 'spades_jack',
  spades_queen = 'spades_queen',
  spades_king = 'spades_king',

  diamonds_2 = 'diamonds_2',
  diamonds_3 = 'diamonds_3',
  diamonds_4 = 'diamonds_4',
  diamonds_5 = 'diamonds_5',
  diamonds_6 = 'diamonds_6',
  diamonds_7 = 'diamonds_7',
  diamonds_8 = 'diamonds_8',
  diamonds_9 = 'diamonds_9',
  diamonds_10 = 'diamonds_10',
  diamonds_ace = 'diamonds_ace',
  diamonds_jack = 'diamonds_jack',
  diamonds_queen = 'diamonds_queen',
  diamonds_king = 'diamonds_king',

  Background = 'Background',
  UndefinedCard = 'UndefinedCard',
  JokerCardState = 'JokerCardState',
}

export interface IProps {
  name: IconNames;
  onClick?: () => void;
  className?: string;
}

const IconsMap: { [key: string]: any } = Icons;

export const Icon = ({ name, onClick, className }: IProps) => {
  const IconComponent = IconsMap[name];

  return <IconComponent onClick={onClick} className={className} />;
};
