import { put, select } from 'redux-saga/effects';
import {
  setCardsStatusToStore,
  // changeGameStatusAction,
  // startDrawSuccessAction,
  // changeGameStateAction,
  setIsScanTime,
} from 'data/reducers';
import { DrawStartedResponse, Notifications } from 'types';
import { gameCardsStatus } from 'constants/common';
import { getSelectedTableRoundInfo } from 'core/selectors/tablesSelectors';
// import { gameStatus } from 'constants/common';

export function* drawStarted({ type: notificationType }: DrawStartedResponse) {
  if (notificationType === Notifications.DRAW_STARTED) {
    // yield put(changeGameStateAction({ tableState: TableStates.DrawStarted }));
    // yield put(changeGameStatusAction({ status: gameStatus.StartedDraw }));
    // yield put(startDrawSuccessAction());
    const { cards } = yield select(getSelectedTableRoundInfo);
    yield put(
      setCardsStatusToStore(
        cards?.length ? gameCardsStatus.WaitingNextCard : gameCardsStatus.ScanFirstCard,
      ),
    );
    yield put(setIsScanTime(true));
  }
}
