import { put } from 'redux-saga/effects';
import {
  changeGameStatusAction,
  setCardsStatusToStore,
  setIsRoundEnd,
  // saveRoundDataToStore,
  // changeGameStatusAction,
  // drawFinishedSuccessAction,
  // changeGameStateAction,
  setIsScanTime,
  setWinnerToStore,
} from 'data/reducers';
import { DrawFinishedResponse, Notifications } from 'types';
import { gameStatus } from 'constants/common';
// import { gameStatus } from 'constants/common';

export function* drawFinished({ type: notificationType, data: result }: DrawFinishedResponse) {
  if (notificationType === Notifications.DRAW_FINISHED) {
    const { winner } = result;
    // yield put(saveRoundDataToStore({ winner, drawTime }));
    // yield put(changeGameStatusAction({ status: `${gameStatus.ConfirmResult} ${drawTime} sec` }));
    // yield put(changeGameStateAction({ tableState: TableStates.DrawFinished }));
    // yield put(drawFinishedSuccessAction());
    yield put(changeGameStatusAction({ status: gameStatus.EndRound }));
    yield put(setWinnerToStore(winner));
    yield put(setIsScanTime(false));
    yield put(setIsRoundEnd(true));
    yield put(setCardsStatusToStore(null));
  }
}
