import { ResultBall } from 'types';

export const InfoPlaceholder = '-';

export const ShiftManagerIdLength = 5;
export const DealerIdLength = 3;

export const gameStatus = {
  OpenRound: 'Start round',
  BettingTime: 'Betting time',
  StartDraw: 'No more bets. Start draw',
  StartedDraw: 'Draw was started',
  ConfirmResult: 'Ball was drawn at',
  Loading: 'Loading...',
  PlaceBets: 'Place your bet',
  NoMoreBets: 'No more bets',
  EndRound: 'End round',
  JokerCard: 'Joker card',
};

export const gameCardsStatus = {
  WaitingNextCard: 'Scan next card',
  ScanFirstCard: 'Scan first card',
  ScanJoker: 'Scan Joker card',
  Wait: 'Wait',
};

export const ballOptions = [
  { value: ResultBall.BLUE, label: 'Blue' },
  { value: ResultBall.RED, label: 'Red' },
  { value: ResultBall.GOLD, label: 'Gold' },
];
export const responseType = {
  Error: 'ERROR',
  Ok: 'OK',
};

export const errorMessages = {
  IncorrectId: 'Incorrect ID',
  NotAvailableId: 'Not available ID',
};

export const alerts = {
  TABLE_CLOSED: {
    text: `Table will be closed after round's end!`,
  },
  ROUND_CANCELED: {
    text: 'Last round was canceled in Admin Panel',
  },
};
