import { call } from 'redux-saga/effects';

import { Notifications } from 'types';

import { webSocketService } from 'services/WebSocketService';
import { changeResultAction } from 'data/reducers';
import { ActionType } from 'typesafe-actions';

export function* changeResultSaga({
  payload: { shiftManagerId, winner },
}: ActionType<typeof changeResultAction>) {
  yield call(() =>
    webSocketService.sendMessage({
      type: Notifications.CHANGE_RESULT,
      data: { shiftManagerId, winner },
    }),
  );
}
