import { ActionType, createAction, createReducer } from 'typesafe-actions';

import { ICustomError } from 'types';
import { ACTIONS } from '../constants';

interface IWebSocketState {
  webSocketConnection: boolean;
  isGameMachineConnected: boolean;
  isSocketDisconnected: boolean;
  tableOpened: boolean;
  isTableTaken: boolean;
}
export const connectToWebSocketAction = createAction(ACTIONS.CONNECT_TO_WEBSOCKET)<{
  tableId: string;
  token: string;
}>();
export const connectToWebSocketSuccessAction = createAction(ACTIONS.CONNECT_TO_WEBSOCKET_SUCCESS)();
export const connectToWebSocketFailureAction = createAction(
  ACTIONS.CONNECT_TO_WEBSOCKET_FAILURE,
)<ICustomError>();

export const disconnectFromWebSocketAction = createAction(ACTIONS.DISCONNECT_FROM_WEBSOCKET)();
export const clearSagaChannels = createAction(ACTIONS.CLEAR_SAGA_CHANNELS)();

export const openTableAction = createAction(ACTIONS.OPEN_TABLE)<{ id: string }>();

export const openTableFailureAction = createAction(ACTIONS.OPEN_TABLE_FAILURE)<{
  status: number;
  message: string;
}>();
export const openTableSuccessAction = createAction(ACTIONS.OPEN_TABLE_SUCCESS)();

export const tableClosedAction = createAction(ACTIONS.TABLE_CLOSED)();
export const tableClosedSuccessAction = createAction(ACTIONS.TABLE_CLOSED_SUCCESS)();

export const gameMachineConnectedAction = createAction(ACTIONS.GAME_MACHINE_CONNECTED)();
export const gameMachineDisconnectedAction = createAction(ACTIONS.GAME_MACHINE_DISCONNECTED)();

export const socketConnectedAction = createAction(ACTIONS.SOCKET_CONNECTED)();
export const socketDisconnectedAction = createAction(ACTIONS.SOCKET_DISCONNECTED)();

export const tableTakenAction = createAction(ACTIONS.TABLE_TAKEN)();
export const tableNoTakenAction = createAction(ACTIONS.TABLE_NO_TAKEN)();

export const closeAction = createAction(ACTIONS.CLOSE_TABLE_ACTION)();

export const defaultState: IWebSocketState = {
  webSocketConnection: false,
  isGameMachineConnected: false,
  isSocketDisconnected: false,
  tableOpened: false,
  isTableTaken: false,
};

const actions = {
  connectToWebSocketSuccessAction,
  disconnectFromWebSocketAction,
  openTableSuccessAction,
  tableClosedSuccessAction,
  gameMachineConnectedAction,
  gameMachineDisconnectedAction,
  socketConnectedAction,
  socketDisconnectedAction,
  tableTakenAction,
  tableNoTakenAction,
  closeAction,
};

export const websocketReducer = createReducer<IWebSocketState, ActionType<typeof actions>>(
  defaultState,
)
  .handleAction(connectToWebSocketSuccessAction, (state) => ({
    ...state,
    webSocketConnection: true,
  }))
  .handleAction(disconnectFromWebSocketAction, (state) => ({
    ...state,
    webSocketConnection: false,
  }))
  .handleAction(gameMachineConnectedAction, (state) => ({
    ...state,
    isGameMachineConnected: true,
  }))
  .handleAction(gameMachineDisconnectedAction, (state) => ({
    ...state,
    isGameMachineConnected: false,
  }))
  .handleAction(socketConnectedAction, (state) => ({
    ...state,
    isSocketDisconnected: false,
  }))
  .handleAction(socketDisconnectedAction, (state) => ({
    ...state,
    isSocketDisconnected: true,
  }))
  .handleAction(openTableSuccessAction, (state) => ({
    ...state,
    tableOpened: true,
  }))
  .handleAction(tableClosedSuccessAction, (state) => ({
    ...state,
    tableOpened: false,
  }))
  .handleAction(tableTakenAction, (state) => ({
    ...state,
    isTableTaken: true,
  }))
  .handleAction(tableNoTakenAction, (state) => ({
    ...state,
    isTableTaken: false,
  }))
  .handleAction(closeAction, (state) => ({
    ...state,
    tableOpened: false,
  }));
