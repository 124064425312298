import React, { useCallback, useState } from 'react';
import { SelectOptionType, ResultBall } from 'types';
import { PopupChangeResultComponent } from './PopupChangeResult';

interface IProps {
  options: SelectOptionType<ResultBall>[];
  loading: boolean;
  onSubmit: (value: ResultBall) => void;
  onClose: () => void;
}

export const PopupChangeResult = ({ options, loading, onSubmit, onClose }: IProps) => {
  const [resultValue, setResultValue] = useState<ResultBall>();

  const onChange = useCallback((value: ResultBall) => {
    setResultValue(value);
  }, []);

  const onConfirmClick = useCallback(() => {
    if (resultValue !== undefined) {
      onSubmit(resultValue);
    }
  }, [resultValue, onSubmit]);

  return (
    <PopupChangeResultComponent
      options={options}
      value={resultValue}
      loading={loading}
      onChange={onChange}
      onConfirmClick={onConfirmClick}
      onClose={onClose}
    />
  );
};
