import React, { useEffect, useRef } from 'react';
// import { ResultBall } from 'types';
import { Icon, IconNames } from 'components/atoms';
import { ICard } from 'types';

import { IconCard, Cards, ResultValueBG, CardsBox } from './styles';

export interface IProps {
  cards: ICard[];
  hasDoubleCard: boolean;
  hasUndefinedCard: boolean;
}

export const ListCards = ({ cards, hasDoubleCard, hasUndefinedCard }: IProps) => {
  const scrollableRef = useRef(null);

  const lengthCards = hasUndefinedCard ? Number(cards?.length) + 1 : cards?.length;

  useEffect(() => {
    scrollToBottom();
  }, [cards, hasDoubleCard, hasUndefinedCard]);

  function scrollToBottom() {
    if (scrollableRef.current) {
      // @ts-ignore
      scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
    }
  }

  return (
    <>
      {cards !== undefined || hasUndefinedCard ? (
        <ResultValueBG name={IconNames.Background} />
      ) : null}
      {cards?.length > 29 && (
        <CardsBox ref={scrollableRef}>
          <Cards>
            {cards?.map(({ suit, rank }: any, index: number) => (
              <IconCard
                key={`${suit}_${rank}_${index}`}
                $numberOfCards={lengthCards}
                $height={
                  lengthCards < 3 ? 240 : lengthCards > 8 ? 90 : lengthCards > 6 ? 120 : 160
                }>
                <Icon name={`${suit}_${rank}` as IconNames} />
              </IconCard>
            ))}
            {hasUndefinedCard && (
              <IconCard
                key="undefined_card"
                $numberOfCards={lengthCards}
                $height={
                  lengthCards < 3 ? 240 : lengthCards > 8 ? 90 : lengthCards > 6 ? 120 : 160
                }>
                <Icon name={IconNames.UndefinedCard} />
              </IconCard>
            )}
          </Cards>
        </CardsBox>
      )}
      {cards?.length <= 29 && (
        <Cards>
          {cards?.map(({ suit, rank }: any, index: number) => (
            <IconCard
              key={`${suit}_${rank}_${index}`}
              $numberOfCards={lengthCards}
              $height={lengthCards < 3 ? 240 : lengthCards > 8 ? 90 : lengthCards > 6 ? 120 : 160}>
              <Icon name={`${suit}_${rank}` as IconNames} />
            </IconCard>
          ))}
          {hasUndefinedCard && (
            <IconCard
              key="undefined_card"
              $numberOfCards={lengthCards}
              $height={lengthCards < 3 ? 240 : lengthCards > 8 ? 90 : lengthCards > 6 ? 120 : 160}>
              <Icon name={IconNames.UndefinedCard} />
            </IconCard>
          )}
        </Cards>
      )}
    </>
  );
};
