export const cardsInfo: any = {
  clubs_2: 'Two of Clubs',
  clubs_3: 'Three of Clubs',
  clubs_4: 'Four of Clubs',
  clubs_5: 'Five of Clubs',
  clubs_6: 'Six of Clubs',
  clubs_7: 'Seven of Clubs',
  clubs_8: 'Eight of Clubs',
  clubs_9: 'Nine of Clubs',
  clubs_10: 'Ten of Clubs',
  clubs_jack: 'Jack of Clubs',
  clubs_queen: 'Queen of Clubs',
  clubs_king: 'King of Clubs',
  clubs_ace: 'Ace of Clubs',

  hearts_2: 'Two of Hearts',
  hearts_3: 'Three of Hearts',
  hearts_4: 'Four of Hearts',
  hearts_5: 'Five of Hearts',
  hearts_6: 'Six of Hearts',
  hearts_7: 'Seven of Hearts',
  hearts_8: 'Eight of Hearts',
  hearts_9: 'Nine of Hearts',
  hearts_10: 'Ten of Hearts',
  hearts_jack: 'Jack of Hearts',
  hearts_queen: 'Queen of Hearts',
  hearts_king: 'King of Hearts',
  hearts_ace: 'Ace of Hearts',

  spades_2: 'Two of Spades',
  spades_3: 'Three of Spades',
  spades_4: 'Four of Spades',
  spades_5: 'Five of Spades',
  spades_6: 'Six of Spades',
  spades_7: 'Seven of Spades',
  spades_8: 'Eight of Spades',
  spades_9: 'Nine of Spades',
  spades_10: 'Ten of Spades',
  spades_jack: 'Jack of Spades',
  spades_queen: 'Queen of Spades',
  spades_king: 'King of Spades',
  spades_ace: 'Ace of Spades',

  diamonds_2: 'Two of Diamonds',
  diamonds_3: 'Three of Diamonds',
  diamonds_4: 'Four of Diamonds',
  diamonds_5: 'Five of Diamonds',
  diamonds_6: 'Six of Diamonds',
  diamonds_7: 'Seven of Diamonds',
  diamonds_8: 'Eight of Diamonds',
  diamonds_9: 'Nine of Diamonds',
  diamonds_10: 'Ten of Diamonds',
  diamonds_jack: 'Jack of Diamonds',
  diamonds_queen: 'Queen of Diamonds',
  diamonds_king: 'King of Diamonds',
  diamonds_ace: 'Ace of Diamonds',
};
